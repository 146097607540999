import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMedia } from 'react-use';
import { ShopTypes } from '~/lib/data-contract';
import { useFreight, useTranslations } from '~/services';
import { Button } from '~/shared/components/Button/Button';
import { ErrorMessage, Form, Input } from '~/shared/components/Form';
import { LoadingSpinner } from '~/shared/components/LoadingSpinner';
import { Text } from '~/shared/components/Text';
import { useCurrency } from '~/shared/hooks';
import { usePostalCode } from '~/shared/utils/storage/hooks';
import { Validation } from '~/shared/utils/validation';
import useModalState from '~/state/useModalState';
import { queries } from '~/theme';
import { ProductNotification } from '../ProductNotifications/ProductNotifications';
import {
    Error,
    FormArea,
    FreightPriceModalWrapper,
    Intro,
    VisualInputElement,
    VisualPlaceholder,
    VisualValue,
} from './styled';
import useQuickModalState from '~/state/useQuickAddToBasketState';

interface Props {
    itemNo?: string;
    onSuccess: () => void;
}

const FreightPriceModal = ({ itemNo, onSuccess }: Props) => {
    const { translate } = useTranslations();
    const formatCurrency = useCurrency();
    const isDesktop = useMedia(queries.md, false);
    const [postalCode, setPostalCode] = usePostalCode();
    const methods = useForm<FormFields>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            [Fields.PostalCode]: postalCode ?? '',
        },
    });
    const { formState, watch, setValue, getValues } = methods;
    const {
        data: freight,
        isFetching,
        isSuccess,
        isError,
    } = useFreight({
        postalCode: formState.isValid ? getValues(Fields.PostalCode) : undefined,
        itemNo,
    });
    const { freightPriceSuccessCallback } = useModalState();

    const { quickFreightPriceSuccessCallback } = useQuickModalState((state) => ({
        quickFreightPriceSuccessCallback: state.freightPriceSuccessCallback,
    }));

    function onSubmit(formData: FormFields) {
        setPostalCode(formData[Fields.PostalCode]);
        onSuccess();
    }

    useEffect(() => {
        const subscription = watch((form) => {
            const { postalCode = '' } = form;
            const cleanPostalCode = postalCode.replace(/\D/g, '').substring(0, 4);

            if (postalCode !== cleanPostalCode || cleanPostalCode.length > 4) {
                setValue(Fields.PostalCode, cleanPostalCode);
            }
        });

        return () => subscription.unsubscribe();
    }, [watch, setValue]);

    const freightZoneIsIsland =
        freight?.deliveryPriceDetailsState === ShopTypes.DeliveryPriceDetailsState.IsIsland;

    const freightZoneIsInvalid =
        isSuccess &&
        freight?.deliveryPriceDetailsState !== ShopTypes.DeliveryPriceDetailsState.Valid &&
        !freightZoneIsIsland;

    const shouldDisplayPrice =
        !freightZoneIsInvalid && typeof freight?.totalPrice?.priceInclVat !== 'undefined';

    return (
        <FreightPriceModalWrapper>
            <Form methods={methods} onSubmit={onSubmit}>
                <Intro>
                    <Text as="h2" variant="display4">
                        {translate('freightCalculator.title')}
                    </Text>
                    <Text>{translate('freightCalculator.text')}</Text>
                </Intro>

                <FormArea>
                    <Input
                        type="number"
                        inputMode="numeric"
                        autoFocus={isDesktop}
                        max={9999}
                        min={1}
                        step={1}
                        name={Fields.PostalCode}
                        pattern={{
                            value: Validation.PostalCode,
                            message: translate('checkout.validation.postalCode'),
                        }}
                        hideErrorMessage
                        placeholder={translate('freightCalculator.postalCode')}
                        required
                        valueAsNumber={false}
                    />

                    <VisualInputElement>
                        <VisualPlaceholder>
                            {translate('freightCalculator.price')}
                        </VisualPlaceholder>

                        <VisualValue>
                            {isFetching ? (
                                <LoadingSpinner size={20} />
                            ) : shouldDisplayPrice ? (
                                formatCurrency(freight?.totalPrice?.priceInclVat)
                            ) : null}
                        </VisualValue>
                    </VisualInputElement>
                </FormArea>

                {isError && (
                    <ErrorMessage>
                        <Error>{translate('freightCalculator.errorMessage')}</Error>
                    </ErrorMessage>
                )}

                {freightZoneIsInvalid && (
                    <ErrorMessage>
                        <Error>{translate('freightCalculator.invalidFreightZone')}</Error>
                    </ErrorMessage>
                )}

                {freightZoneIsIsland && (
                    <ProductNotification
                        content={translate('freightCalculator.islandFreightZone')}
                        variant="high"
                    />
                )}

                <Button
                    type="submit"
                    disabled={!isSuccess || freightZoneIsInvalid}
                    style={{ width: '100%' }}
                >
                    {freightPriceSuccessCallback || quickFreightPriceSuccessCallback
                        ? translate('product.addToCart')
                        : translate('freightCalculator.submitButton')}
                </Button>
            </Form>
        </FreightPriceModalWrapper>
    );
};

type FormFields = {
    [Fields.PostalCode]: string;
};

enum Fields {
    PostalCode = 'postalCode',
}

export default FreightPriceModal;
