import { AnimatePresence } from 'framer-motion';
import { useMedia } from 'react-use';
import { FreightPriceModal, ProductStatusModal } from '~/features/ProductDetail';
import Arrow from '~/icons/iconography/arrow/right.svg';
import { useTranslations } from '~/services';
import { resetQuickAdd, useModalFreight } from '~/state/useModalFreight';
import useQuickModalState, {
    closeModal,
    resetFreightModalID,
    setQuickFreightSuccessCallback,
} from '~/state/useQuickAddToBasketState';

import { queries } from '~/theme';
import QuickAddToBasketInitial from './QuickToBasketInitial/QuickToBasketInitial';
import {
    StyledAddToBasket,
    StyledButton,
    StyledButtonWrapper,
    StyledFreightWrapper,
    StyledPickup,
    StyledWrapper,
} from './styled';

export default function QuickAddProduct() {
    const { freightQuickPriceModalId, quickAddToBasketModalId, freightPriceSuccessCallback } =
        useQuickModalState((state) => ({
            quickAddToBasketModalId: state.quickAddToBasketModalId,
            freightQuickPriceModalId: state.freightQuickPriceModalId,
            freightPriceSuccessCallback: state.freightPriceSuccessCallback,
        }));

    const { translate } = useTranslations();
    const isMobile = useMedia(queries.xs, true);
    const { isQuickAdd } = useModalFreight();

    return (
        <>
            <AnimatePresence mode={'wait'}>
                <StyledWrapper>
                    <StyledButtonWrapper
                        initial={{ x: '20%', opacity: 0, display: 'none' }}
                        animate={{
                            x: freightQuickPriceModalId || isQuickAdd ? 0 : '20%',
                            opacity: freightQuickPriceModalId || isQuickAdd ? 1 : 0,
                            display: freightQuickPriceModalId || isQuickAdd ? 'block' : 'none',
                        }}
                        exit={{ x: '20%', opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        key={'backButton'}
                    >
                        <StyledButton
                            onClick={() => {
                                resetQuickAdd();
                                resetFreightModalID();
                            }}
                            customIcon={<Arrow />}
                            variant="stroke"
                        >
                            {translate('generic.back')}
                        </StyledButton>
                    </StyledButtonWrapper>
                    <StyledFreightWrapper
                        initial={{ x: '100%', opacity: 0, display: 'none' }}
                        animate={{
                            x: freightQuickPriceModalId && !isQuickAdd ? 0 : '100%',
                            opacity: freightQuickPriceModalId && !isQuickAdd ? 1 : 0,
                            display: freightQuickPriceModalId && !isQuickAdd ? 'block' : 'none',
                        }}
                        exit={{ x: '100%', opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        key={'freightPriceModal'}
                    >
                        <FreightPriceModal
                            itemNo={quickAddToBasketModalId}
                            onSuccess={() => {
                                resetFreightModalID();
                                freightPriceSuccessCallback?.();
                                if (freightPriceSuccessCallback) {
                                    closeModal();
                                    setQuickFreightSuccessCallback(undefined);
                                }
                            }}
                        />
                    </StyledFreightWrapper>
                    <StyledAddToBasket
                        initial={{ x: isMobile ? 0 : '-100%', opacity: 0, display: 'none' }}
                        animate={{
                            x: freightQuickPriceModalId || isQuickAdd ? '-100%' : 0,
                            opacity: freightQuickPriceModalId || isQuickAdd ? 0 : 1,
                            display: freightQuickPriceModalId || isQuickAdd ? 'none' : 'block',
                        }}
                        exit={{ x: '-100%', opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        key={'basketInitial'}
                    >
                        <QuickAddToBasketInitial itemNo={quickAddToBasketModalId} />
                    </StyledAddToBasket>

                    <StyledPickup
                        initial={{ x: '100%', opacity: 0, display: 'none' }}
                        animate={{
                            x: isQuickAdd ? 0 : '100%',
                            opacity: isQuickAdd ? 1 : 0,
                            display: isQuickAdd ? 'grid' : 'none',
                        }}
                        exit={{ x: '100%', opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        key={'productStatusModal'}
                        style={{ overflowY: 'scroll' }}
                    >
                        <ProductStatusModal modalType={'quickAdd'} />
                    </StyledPickup>
                </StyledWrapper>
            </AnimatePresence>
        </>
    );
}
