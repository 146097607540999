import { PickUpModal } from './PickUpModal';
import { PowerStep } from './PowerStep';
import QuickAddToBasket from './QuickAddToBasket/QuickAddToBasket';

export default function GlobalModals() {
    return (
        <>
            <QuickAddToBasket />
            <PowerStep />
            <PickUpModal />
        </>
    );
}
