import React from 'react';
import { useInputHighlight } from '../hooks';
import useFormInput from '../hooks/useFormInput';
import ErrorMessage from './ErrorMessage';
import {
    Column,
    IconError,
    IconSuccess,
    StyledTextArea,
    TextAreaPlaceholder,
    TextAreaWrapper,
} from './styled';
import { TextAreaProps } from './types';

const TextArea = ({
    name,
    hideIcon,
    pattern,
    required,
    validate,
    ...rest
}: TextAreaProps): JSX.Element => {
    const { error, inputValue, onBlur, register } = useFormInput(name);

    const { isHighlighted, setHasFocus } = useInputHighlight(Boolean(inputValue));

    const { placeholder, hideErrorMessage, ...restInput } = rest;

    const placeholderText = inputValue && !error ? placeholder : `${placeholder}`;

    return (
        <Column>
            <TextAreaWrapper isHighlighted={isHighlighted}>
                <TextAreaPlaceholder isHighlighted={isHighlighted} htmlFor={name}>
                    {placeholderText}
                </TextAreaPlaceholder>

                <StyledTextArea
                    {...restInput}
                    {...register(name, { pattern, required, validate })}
                    aria-invalid={Boolean(error)}
                    onBlur={() => {
                        setHasFocus(false);
                        onBlur();
                    }}
                    onFocus={() => {
                        setHasFocus(true);
                    }}
                />

                {hideIcon ? null : error ? <IconError /> : Boolean(inputValue) && <IconSuccess />}
            </TextAreaWrapper>

            {!hideErrorMessage && error && <ErrorMessage>{error as string}</ErrorMessage>}
        </Column>
    );
};

export default TextArea;
