import { BaseSyntheticEvent, FormHTMLAttributes } from 'react';
import { FieldValues, FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';

type FormProps<Fields extends FieldValues> = Omit<
    FormHTMLAttributes<HTMLFormElement>,
    'methods' | 'onSubmit'
> & {
    methods: UseFormReturn<Fields>;
    onSubmit: SubmitHandler<Fields>;
};

const Form = <FormData extends FieldValues>({
    children,
    methods,
    onSubmit,
    ...rest
}: FormProps<FormData>) => {
    const { handleSubmit } = methods;

    return (
        <FormProvider {...methods}>
            <form
                {...rest}
                onSubmit={(event?: BaseSyntheticEvent) => {
                    event?.stopPropagation();
                    handleSubmit(onSubmit)(event);
                }}
            >
                {children}
            </form>
        </FormProvider>
    );
};

export default Form;
