import { AnimatePresence } from 'framer-motion';
import { ReactNode } from 'react';
import { Success } from './styled'; // Make sure you have a Success styled component

interface Props {
    children: ReactNode;
}

const SuccessMessage = ({ children, ...rest }: Props) => (
    <AnimatePresence>
        {children && (
            <Success
                role="status" // Accessible status role for success
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ ease: 'easeOut' }}
            >
                <div {...rest}>{children}</div>
            </Success>
        )}
    </AnimatePresence>
);

export default SuccessMessage;
