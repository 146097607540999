import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import Minus from '~/icons/iconography/minus.svg';
import Plus from '~/icons/iconography/plus.svg';
import { AddOne, Amount, MinusOne, SmallWrapper, Wrapper } from './styled';

const DEBOUNCE_DELAY = 500;
const MAX_AMOUNT = 9999;
const MIN_AMOUNT = 1;

interface SelectAmountProps {
    debounce?: boolean;
    disabled?: boolean;
    name: string;
    smallSize?: boolean;
}

export const SelectAmount = ({ debounce, disabled, name, smallSize }: SelectAmountProps) => {
    const { watch, register, setValue } = useFormContext();
    const [amount, setAmount] = useState(watch(name));
    const [debouncedItemQuantity] = useDebounce(amount, debounce ? DEBOUNCE_DELAY : 0);

    // Update input field on form update
    useEffect(() => {
        const subscription = watch((values) => {
            setAmount(values?.[name]);
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [name, watch]);

    // Update form value after debounce delay
    useEffect(() => {
        if (!debouncedItemQuantity || isNaN(debouncedItemQuantity) || debouncedItemQuantity < 1) {
            return;
        }

        setValue(name, Math.max(MIN_AMOUNT, Math.min(MAX_AMOUNT, debouncedItemQuantity)));
    }, [debouncedItemQuantity, name, setValue]);

    const WrapperComponent = smallSize ? SmallWrapper : Wrapper;

    return (
        <WrapperComponent>
            <MinusOne
                type="button"
                disabled={disabled || amount <= MIN_AMOUNT}
                onClick={() => setAmount(Math.max(MIN_AMOUNT, amount - 1))}
            >
                <Minus />
            </MinusOne>

            <Amount
                type="number"
                inputMode="numeric"
                disabled={disabled}
                min={MIN_AMOUNT}
                max={MAX_AMOUNT}
                {...register(name)}
                onChange={(event) => setAmount(parseInt(event.target.value, 10))}
                value={amount}
            />

            <AddOne
                type="button"
                disabled={disabled || amount >= MAX_AMOUNT}
                onClick={() => setAmount(Math.min(MAX_AMOUNT, amount + 1))}
            >
                <Plus />
            </AddOne>
        </WrapperComponent>
    );
};
