import Router from 'next/router';
import { ShopTypes } from '~/lib/data-contract';

const prefetch = new Set<string>();

export function prefetchVariants(
    product: ShopTypes.ProductQueryProduct | undefined,
    itemNo: string,
) {
    product?.variants?.forEach((variant) => {
        if (!variant?.itemUrlSegment || variant.itemNumber === itemNo) {
            return;
        }

        const slug = (Router.query?.slug as string[])?.slice(0, -1);
        slug?.push(variant?.itemUrlSegment);

        const variantUrl = '/' + (slug ?? []).join('/');

        if (!prefetch.has(variantUrl)) {
            prefetch.add(variantUrl);
            Router.prefetch(variantUrl);
        }
    });
}
