import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const Intro = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[2],
}));

export const FormArea = styled.div(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.space[2],
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: theme.space[4],

    [breakpoints.sm]: {
        flexDirection: 'row',
    },
}));

export const FreightPriceModalWrapper = styled.div(({ theme }) => ({
    height: '100%',

    ' > form': {
        display: 'grid',
        height: '100%',
        gridTemplateRows: 'auto auto 1fr',

        '> button': {
            alignSelf: 'end',
            marginBottom: theme.space[5],
        },
    },
}));

// Fake input
export const VisualInputElement = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.space[4],
    alignItems: 'center',
    height: theme.space[6],
    margin: `${theme.space[2]} 0`,
    padding: `0 ${theme.space[2]}`,
    border: `1px solid ${theme.colors.grey50}`,
    borderRadius: 2,
}));

export const VisualPlaceholder = styled.div(({ theme }) => ({
    color: theme.colors.grey60,
    fontSize: theme.fontSizes.mouse,
    lineHeight: theme.lineHeights.rat,
    flexShrink: 0,
    userSelect: 'none',
}));

export const VisualValue = styled.div(({ theme }) => ({
    color: theme.colors.thunderBlack,
    fontSize: theme.fontSizes.mouse,
    lineHeight: theme.lineHeights.rat,
    width: '100%',
    textAlign: 'right',
    position: 'relative',

    svg: {
        fill: theme.colors.thunderBlack,
        left: 'inherit',
        right: `-${theme.space[2]}`,
    },
}));

export const Error = styled.div(({ theme }) => ({
    marginBottom: theme.space[3],
}));
