import { ProductCard } from '~/features/ProductList/components/ProductCard';
import {
    useComplementaryResults,
    useRelatedResults,
    useTranslations,
    useUserProfile,
} from '~/services';

import { ModuleCarousel } from '~/templates/blocks/shared/ModuleCarousel';
import { CarouselContainer } from './styled';
import { useSession } from 'next-auth/react';

type Props = {
    productId: string;
    wrapperRef?: React.RefObject<HTMLDivElement>;
};
export default function PowerStepCarousel({ productId, wrapperRef }: Props) {
    const { translate } = useTranslations();
    const { data: relatedProducts, isLoading: relatedLoading } = useRelatedResults(productId);
    const { data: complementary, isLoading: complementaryLoading } =
        useComplementaryResults(productId);

    const { data: sessionData } = useSession();
    const { data: profile } = useUserProfile();
    const token = sessionData?.accessToken ?? null;
    const customerId = profile?.customer?.id ?? '';

    const hasComplementaryProducts = Boolean(complementary?.results?.items?.length);
    const headline = hasComplementaryProducts
        ? translate('generic.itemsMatchProduct')
        : translate('generic.othersHaveAlsoBought');

    const productsFromLoop54 = hasComplementaryProducts
        ? complementary?.results?.items
        : relatedProducts?.results?.items;

    const isLoading = relatedLoading || complementaryLoading;

    const productCards = isLoading
        ? Array.from({ length: 10 }).map((_, index) => <ProductCard.Skeleton key={index} />)
        : productsFromLoop54?.map((item, index) => (
              <ProductCard
                  key={item.SKU + index}
                  product={item}
                  translate={translate}
                  itemListName={headline}
                  isWithinModal
                  customerId={customerId}
                  token={token}
              />
          ));

    if (!productsFromLoop54?.length && !isLoading) {
        return null;
    }

    return (
        <CarouselContainer>
            <ModuleCarousel
                items={productCards}
                wrapperRef={wrapperRef}
                headline={headline}
                isProducts
            />
        </CarouselContainer>
    );
}
