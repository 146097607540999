import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Button } from '~/shared/components/Button';
import { IconWrapper, Wrapper } from '~/shared/styles/Button';

export const StyledWrapper = styled.div(() => ({
    display: 'grid',
    gridTemplateAreas: `
        "one"
    `,
    height: '100%',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    border: 'none',
    padding: 0,
    color: theme.colors.black,

    [`${Wrapper}`]: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        gap: 10,
    },

    [`${IconWrapper}`]: {
        transform: 'rotate(180deg)',
        display: 'flex',
        alignItems: 'center',
        marginBottom: 0,
        width: 20,
        height: 20,
    },
}));

export const StyledButtonWrapper = styled(motion.div)({
    position: 'absolute',
    top: 13,
    left: 15,
});

export const StyledFreightWrapper = styled(motion.div)({
    height: '100%',
    gridArea: 'one',
});

export const StyledAddToBasket = styled(motion.div)({
    height: '100%',
    gridArea: 'one',
});

export const StyledPickup = styled(motion.div)(({ theme }) => ({
    height: '100%',
    gridArea: 'one',
    display: 'grid',
    gridTemplateRows: 'repeat(3,auto) 1fr',
    rowGap: theme.space[5],
    paddingBottom: theme.space[2],
    '> div:first-of-type': {
        alignSelf: 'end',
        paddingBottom: theme.space[2],
    },
}));
