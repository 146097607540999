import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledGutter = styled.div({
    paddingLeft: 30,
    paddingRight: 30,
    [breakpoints.xs]: {
        paddingLeft: 15,
        paddingRight: 15,
    },
    [breakpoints.md]: {
        paddingLeft: 70,
        paddingRight: 70,
    },
});
