import React from 'react';
import { Container } from './styled';
import { ProductStatusProps } from './types';
import OnlineStock from './components/OnlineStock';
import PickUpStock from './components/PickupStock';

export default function ProductStatusInfo(props: ProductStatusProps) {
    return (
        <Container>
            <OnlineStock {...props} />
            <PickUpStock {...props} />
        </Container>
    );
}
