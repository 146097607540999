import { ReactChild, useMemo } from 'react';
import { getTextColor } from '$theme';
import { UmbracoTypes } from '~/lib/data-contract';
import { LoadingSpinner } from '~/shared/components/LoadingSpinner';
import {
    CloseIcon,
    StyledInfoLink,
    StyledInfoText,
    StyledNotification,
    StyledTitle,
    StyledNotificationContent,
    CustomLink,
} from './styles';

export type NotificationProp = {
    variant?: 'high' | 'low' | 'informative' | 'positive' | 'campaign' | 'addedToCart';
    centered?: boolean;
    content?: string | ReactChild;
    link?: UmbracoTypes.ILink;
    title?: string;
    backgroundColor?: string;
    isCheckout?: boolean;
    isLoading?: boolean;
    closeNotification?: boolean;
    textColor?: string;
    onClose?: () => void;
    className?: string;
};

export const ProductNotification = ({
    backgroundColor,
    centered,
    content,
    isLoading,
    link,
    textColor,
    onClose,
    title,
    variant,
    closeNotification = false,
    className,
}: NotificationProp) => {
    const memoTextColor = useMemo(
        () =>
            textColor ? ensureHexColor(textColor) : ensureHexColor(getTextColor(backgroundColor)),
        [backgroundColor, textColor],
    );

    const isInternalLink = link?.target === '_self' || link?.target === '';

    return (
        <StyledNotification
            className={className}
            centered={centered}
            variant={variant}
            backgroundColor={ensureHexColor(backgroundColor)}
            textColor={memoTextColor}
            closeNotification={closeNotification}
            hasCloseIcon={!!onClose}
        >
            <StyledNotificationContent>
                {title ? <StyledTitle>{title}</StyledTitle> : null}

                {isLoading || content ? (
                    <StyledInfoText>{isLoading ? <LoadingSpinner /> : content}</StyledInfoText>
                ) : null}

                {link?.url && link.text ? (
                    isInternalLink ? (
                        <StyledInfoLink href={link.url} target="_self">
                            {link.text}
                        </StyledInfoLink>
                    ) : (
                        <CustomLink color={memoTextColor} href={link.url} target="_blank">
                            {link.text}
                        </CustomLink>
                    )
                ) : null}
            </StyledNotificationContent>
            {onClose ? <CloseIcon onClick={onClose} /> : null}
        </StyledNotification>
    );
};

export const ensureHexColor = (colorString?: string) =>
    colorString ? (colorString.startsWith('#') ? colorString : `#${colorString}`) : colorString;
