import styled from '@emotion/styled';
import { InputWrapper } from '~/shared/styles/Form';

export const Input = styled.input(({ theme }) => ({
    appearance: 'none',
    backgroundColor: theme.colors.offWhite,
    border: `1px solid ${theme.colors.thunderBlack}`,
    display: 'grid',
    height: theme.fontSizes.chicken,
    margin: `0 ${theme.space[2]} 0 0`,
    minHeight: theme.fontSizes.chicken,
    minWidth: theme.fontSizes.chicken,
    padding: 0,
    placeContent: 'center',
    width: theme.fontSizes.chicken,
    borderRadius: 0,

    '&:checked:before': {
        content: '""',
        backgroundColor: theme.colors.thunderBlack,
        height: '0.60em',
        width: '0.60em',
    },
}));

export const Wrapper = styled(InputWrapper)(({ theme }) => ({
    border: 'none',
    alignItems: 'flex-start',
    padding: 0,
    backgroundColor: theme.colors.offWhite,
}));
