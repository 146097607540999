import React, { forwardRef, useContext, useMemo } from 'react';
import Minus from '~/icons/iconography/minus.svg';
import Plus from '~/icons/iconography/plus.svg';
import {
    StyledAccordionContainer,
    StyledAccordionContent,
    StyledAccordionIconWrapper,
    StyledAccordionItem,
    StyledAccordionTrigger,
    StyledDefaultIcon,
} from '~/shared/styles/Accordion';
import { Text } from '../../../Text';
import { AccordionContext } from '../../context/AccordionContext';
import { AccordionItemProps, AccordionItemRef } from './models';

const defaultIcon = ({ isOpen }: { isOpen: boolean }) => (
    <StyledDefaultIcon as={isOpen ? Minus : Plus} />
);

/**
 * @description
 * Wraps content (children) in an accordion.
 * Should always be used inside a <Accordion>
 *
 * @example
 *  <AccordionItem
 *      icon={<Icon icon="instagram" size="lg" />}
 *      header="Woah this is a cool accordion" />
 *      <p>Accordion body here!</p>
 *  </AccordionItem>
 *
 * @example
 *  <AccordionItem
 *      icon={<Icon icon="instagram" size="lg" />}
 *      header="Woah this is a cool accordion" />
 * .    {({ isOpen }) => (
 *         `This accordion is ${isOpen ? 'open' : 'closed' }!`
 *      )}
 *  </AccordionItem>
 */

export const AccordionItem = forwardRef<AccordionItemRef, AccordionItemProps>(function Component(
    { id, header, icon = defaultIcon, children, includeId },
    ref,
) {
    const { states, disableAnimation, ...context } = useContext(AccordionContext);

    const isOpen = useMemo(() => states.includes(id), [states, id]);

    const methods = useMemo(() => {
        return {
            toggle: () => context.toggle(id),
            open: () => context.open(id),
            close: () => context.close(id),
        };
    }, [id, context]);

    /**
     * Expose methods through ref.
     * The preferred approach with `useImperativeHandle` caused quite a few typescript issues
     */
    const setRefMethods = (element: AccordionItemRef) => {
        if (element && ref) {
            element.open = () => methods.open();
            element.close = () => methods.close();
            element.toggle = () => methods.toggle();

            if (typeof ref === 'function') {
                ref(element);
            } else {
                ref.current = element;
            }
        }
    };

    return (
        <StyledAccordionItem
            value={id}
            key={id}
            ref={setRefMethods}
            id={(includeId && id) || undefined}
        >
            <StyledAccordionTrigger open={isOpen}>
                {typeof header === 'string' ? <Text variant="paragraph">{header}</Text> : header}

                {icon && (
                    <StyledAccordionIconWrapper>
                        {typeof icon === 'function' ? icon({ id, isOpen, ...methods }) : icon}
                    </StyledAccordionIconWrapper>
                )}
            </StyledAccordionTrigger>

            <StyledAccordionContainer disableAnimation={disableAnimation}>
                <StyledAccordionContent>
                    {typeof children === 'function'
                        ? children({ id, isOpen, ...methods })
                        : children}
                </StyledAccordionContent>
            </StyledAccordionContainer>
        </StyledAccordionItem>
    );
});
