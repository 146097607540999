import { animate, useMotionValue } from 'framer-motion';
import { UIEvent, forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useMedia } from 'react-use';
import { Gutter } from '~/shared/components/Gutter';
import { MaxWidth } from '~/shared/components/MaxWidth';
import { weakKey } from '~/shared/utils/jsx';
import { StyledGrabWrapper, StyledGutter, StyledItemsWrapper } from '../styled';
import { StyledScrollArea, StyledScrollbar, StyledThumb, StyledViewport } from './styles';

type Props = {
    items: React.ReactNode[];
    isProducts: boolean;
    onResize?: (scrollDistance: number) => void;
    onScroll?: (options?: UIEvent<HTMLDivElement>) => void;
    wrapperRef?: React.RefObject<HTMLDivElement>;
};

export type CarouselRef = {
    scrollDistance: number;
    scrollLeft: number;
    scrollBy: (options?: ScrollToOptions) => void;
};

const CarouselFn = forwardRef(function Carousel(props: Props, ref) {
    const { items = [], onResize, onScroll, isProducts, wrapperRef } = props;
    const onlyTouch = useMedia('(any-hover:none)', false);

    // The element that will scroll on drag
    const scrollElementRef = useRef<HTMLDivElement>(null);

    // const scrollElementRef = initialScrollElementRef || fallbackScrollElementRef;
    // Element to align the right offset with
    const offsetAlignElementRef = useRef<HTMLDivElement>(null);

    // Keep track of scroll mode without triggering a render
    const scrollModeRef = useRef<'auto' | 'drag' | 'initial'>('initial');

    const [scrollDistance, setScrollDistance] = useState(0);
    const [offsetRight, setOffsetRight] = useState(0);

    const dragValue = useMotionValue(0);

    const showScrollbar = scrollDistance > 0;
    const enableDrag = showScrollbar && !onlyTouch;

    /**
     * Expose a scrollBy method through the forwarded ref.
     * Using it directly on the scrollElement will not work if
     * framer-motion is still animating drag
     */
    useImperativeHandle(ref, () => {
        return {
            get scrollDistance() {
                return scrollDistance;
            },
            get scrollLeft() {
                return scrollElementRef.current?.scrollLeft || 0;
            },
            scrollBy: (options?: ScrollToOptions) => {
                const { current: scrollElement } = scrollElementRef;
                const { left = 0 } = options || {};

                if (scrollElement) {
                    // iOS does not support scrollBehavior smooth.
                    // Using framer-motion to animate the scroll
                    if ('scrollBehavior' in document.documentElement.style) {
                        scrollModeRef.current = 'auto';
                        scrollElement.scrollBy(options);
                    } else {
                        const scrollLeft = scrollElement.scrollLeft;
                        const target = scrollLeft + left;
                        const cappedTarget = Math.max(0, Math.min(scrollDistance, target));
                        const distance = cappedTarget - scrollLeft;

                        // Magic calculation. Between distance/1500 and 0.3s
                        const duration = Math.max(Math.abs(distance) / 1500, 0.3);

                        // Animating the drag value to hook into existing computed
                        // scrolling. Animate will stop if dragValue is updated outside
                        // of the animation
                        animate(dragValue, -cappedTarget, {
                            type: 'tween',
                            ease: 'easeInOut',
                            duration,
                            onPlay() {
                                scrollModeRef.current = 'drag';
                            },
                        });
                    }
                }
            },
        };
    });

    useEffect(() => {
        const { current: scrollElement } = scrollElementRef;

        if (!scrollElement) {
            return;
        }

        // Keep track of scroll element to calculate scroll distance
        // and to set the right offset to the right
        const observer = new window.ResizeObserver((entries) => {
            if (entries[0]) {
                const { current: scrollElement } = scrollElementRef;
                const { current: offsetAlignElement } = offsetAlignElementRef;

                let scrollDistance = 0;
                let offsetRight = 0;

                const elementWidth = wrapperRef?.current?.clientWidth ?? window.innerWidth;

                if (scrollElement && offsetAlignElement) {
                    const totalOffset = elementWidth - offsetAlignElement.clientWidth;
                    const totalScrollWidth = scrollElement.scrollWidth - totalOffset;

                    scrollDistance = totalScrollWidth - offsetAlignElement.clientWidth;
                    offsetRight = totalOffset / 2 - (wrapperRef?.current?.clientWidth ? 30 : 0);
                }

                setScrollDistance(scrollDistance);
                setOffsetRight(offsetRight);

                onResize && onResize(scrollDistance);
            }
        });

        // Track changes made via the motion drag.
        const unsubscribe = dragValue.on('change', (value) => {
            const { current: scrollElement } = scrollElementRef;
            const { current: mode } = scrollModeRef;

            if (scrollElement && mode === 'drag') {
                scrollElement.scrollLeft = -value;
            }
        });

        observer.observe(scrollElement);

        return () => {
            observer.disconnect();
            unsubscribe();
        };
    });

    // Reset drag state when start dragging
    // in case drag position has been altered by user
    const onMouseDownHandler = () => {
        const { current: scrollElement } = scrollElementRef;

        if (scrollElement) {
            const scrollLeft = scrollElement.scrollLeft;
            dragValue.set(-scrollLeft);
        }
    };

    // Prevent default on the initial click, after dragging.
    // This is to prevent cases where the user let's go
    // over an anchor tag and the browser navigates to that page.
    const onClickCaptureHandler = (event: React.MouseEvent<HTMLElement>) => {
        if (scrollModeRef.current === 'drag') {
            event.preventDefault();
        }
    };

    // Make sure dragValue is always updated
    const onScrollHandler = (event: UIEvent<HTMLDivElement>) => {
        const { scrollLeft } = event.currentTarget;

        dragValue.set(-scrollLeft);
        onScroll && onScroll(event);
    };

    return (
        <StyledScrollArea type="auto" onClickCapture={onClickCaptureHandler}>
            <StyledGrabWrapper
                drag={enableDrag ? 'x' : false}
                _dragX={dragValue}
                dragElastic={0}
                dragConstraints={{
                    right: 0,
                    left: -scrollDistance,
                }}
                onDragStart={() => (scrollModeRef.current = 'drag')}
                onDragEnd={() => {
                    setTimeout(() => {
                        // Delayed due to conflicting mouse event navigation
                        scrollModeRef.current = 'auto';
                    }, 50);
                }}
                onMouseDown={onMouseDownHandler}
            >
                <StyledViewport
                    onTouchStart={() => (scrollModeRef.current = 'auto')}
                    onWheel={() => (scrollModeRef.current = 'auto')}
                    ref={scrollElementRef}
                    onScroll={onScrollHandler}
                >
                    <MaxWidth>
                        <StyledGutter>
                            <StyledItemsWrapper isProducts={isProducts}>
                                {items.map((item, index) => {
                                    const isLast = index === items.length - 1;
                                    const paddingRight = isLast ? offsetRight : undefined;
                                    const key = weakKey(item);

                                    return (
                                        <div
                                            style={{
                                                paddingRight,
                                            }}
                                            key={key}
                                        >
                                            {item}
                                        </div>
                                    );
                                })}
                            </StyledItemsWrapper>
                        </StyledGutter>
                    </MaxWidth>
                </StyledViewport>
            </StyledGrabWrapper>
            <MaxWidth>
                <Gutter>
                    <div
                        style={{
                            position: 'relative',
                        }}
                        ref={offsetAlignElementRef}
                    >
                        <StyledScrollbar
                            orientation="horizontal"
                            onMouseDown={() => (scrollModeRef.current = 'auto')}
                        >
                            <StyledThumb />
                        </StyledScrollbar>
                    </div>
                </Gutter>
            </MaxWidth>
        </StyledScrollArea>
    );
});

export const Carousel = memo(CarouselFn);
