import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Button } from '~/shared/components/Button';

export const CampaignNotificationWrapper = styled.div(({ theme }) => ({
    marginBottom: theme.space[6],
}));

export const VariantSelectorButton = styled.button<{ isOpen?: boolean }>(({ isOpen, theme }) => ({
    backgroundColor: 'transparent',
    borderRadius: isOpen ? '2px 2px 0 0' : '2px 2px',
    border: `1px solid ${theme.colors.grey50}`,
    lineHeight: '15px',
    padding: '15px 10px',
    textAlign: 'left',
    width: '100%',
    color: theme.colors.black,

    'span:first-of-type': {
        textTransform: 'capitalize',
    },

    svg: {
        height: theme.space[4],
        overflow: 'visible',
        width: theme.space[4],
    },
}));

export const VariantSelectorItem = styled.li<{ isHighlighted?: boolean }>(
    ({ isHighlighted, theme }) => ({
        backgroundColor: isHighlighted ? theme.colors.brandBlue : 'inherit',
        borderBottom: `1px solid ${theme.colors.grey30}`,
        color: isHighlighted ? theme.colors.white : 'inherit',
        cursor: 'pointer',
        padding: '15px 10px',
        width: '100%',

        '&:hover': {
            backgroundColor: isHighlighted ? theme.colors.brandBlue : theme.colors.grey10,
        },
    }),
);

export const VariantSelectorWrapper = styled.div(({ theme }) => ({
    backgroundColor: 'transparent',
    fontSize: '12px',
    lineHeight: '15px',
    position: 'relative',

    '> ul': {
        backgroundColor: theme.colors.white,
        boxShadow: '0 5px 15px 0 rgb(0 0 0 / 20%)',
        left: 0,
        listStyle: 'none',
        maxHeight: '320px',
        overflowY: 'auto',
        position: 'absolute',
        top: '100%',
        width: '100%',
        zIndex: theme.zIndices[3],
    },
}));

export const BottomProductDataWrapper = styled.div(() => ({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
}));

export const EnergyLabelWrapper = styled.div(({ theme }) => ({
    marginRight: theme.space[7],
}));

export const ButtonWrapper = styled.div(({ theme }) => ({
    display: 'grid',
    gap: theme.space[2],
    gridTemplateColumns: 'repeat(2, 170px)',
    gridTemplateRows: 'auto',
    columnGap: theme.space[2],

    '> button:nth-of-type(1)': {
        color: theme.colors.black,
        alignSelf: 'end',
    },
}));

export const BuyButton = styled(Button)(
    ({ theme }) => ({
        height: theme.space[7],
        fontSize: theme.fontSizes.chicken,
        fontWeight: theme.fontWeights.bold,
        borderColor: theme.colors.black,

        span: {
            fontSize: theme.fontSizes.chicken,
            fontWeight: theme.fontWeights.medium,
        },

        '&:disabled': {
            backgroundColor: theme.colors.grey30,

            span: {
                fontSize: theme.fontSizes.chicken,
                fontWeight: theme.fontWeights.medium,
                color: theme.colors.grey70,
            },
        },
    }),
    ifProp({ variant: 'stroke' }, ({ theme }) => ({
        backgroundColor: theme.colors.white,
    })),
);

export const ErrorMessages = styled.div(({ theme }) => ({
    marginTop: theme.space[2],
    gridColumn: '1/3',
}));
