import styled from '@emotion/styled';
import { InputWrapper } from '~/shared/styles/Form';

export const Wrapper = styled(InputWrapper)({
    alignItems: 'start',
    border: 'none',
    padding: 0,
    backgroundColor: 'initial',
});

export const InnerWrapper = styled.span({
    display: 'block',
    position: 'relative',
    width: '100%',
});

export const Text = styled.span<{ disabled?: boolean }>(({ theme, disabled }) => ({
    display: 'block',
    width: '100%',
    paddingLeft: theme.space[5],

    '&:before, &:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        borderRadius: '50%',
    },

    '&:before': {
        left: 0,
        top: 0,
        border: disabled
            ? `1px solid ${theme.colors.grey70}`
            : `1px solid ${theme.colors.thunderBlack}`,
        height: 16,
        width: 16,
    },

    '&:after': {
        left: 3,
        top: 3,
        transition: 'ease 200ms opacity',
        backgroundColor: theme.colors.thunderBlack,
        height: 10,
        width: 10,
        opacity: 0,
    },
}));

export const Input = styled.input({
    width: 0,
    height: 0,
    position: 'absolute',
    opacity: 0,

    // Active state
    [`&:checked + ${Text}:after`]: {
        opacity: 1,
    },
});
