import React from 'react';
import { InputWrapper, Placeholder } from '~/shared/styles/Form';
import { useInputHighlight } from '../hooks';
import useFormInput from '../hooks/useFormInput';
import ErrorMessage from './ErrorMessage';
import { Column, IconError, IconSuccess, Input as StyledInput } from './styled';
import { InputProps } from './types';

const Input = ({
    name,
    hideIcon,
    pattern,
    required,
    validate,
    hideStar,
    className,
    disabled,
    ...rest
}: InputProps): JSX.Element => {
    const { error, inputValue, onBlur, register } = useFormInput(name);
    const { isHighlighted, setHasFocus } = useInputHighlight(Boolean(inputValue));
    const { placeholder, hideErrorMessage, valueAsNumber, ...restInput } = rest;
    const placeholderText = (inputValue && !error) || hideStar ? placeholder : `${placeholder}*`;

    return (
        <Column>
            <InputWrapper className={className} isHighlighted={isHighlighted} disabled={disabled}>
                <Placeholder isHighlighted={isHighlighted} htmlFor={name}>
                    {placeholderText}
                </Placeholder>

                <StyledInput
                    {...restInput}
                    {...register(name, {
                        pattern,
                        required,
                        validate,
                        valueAsNumber: valueAsNumber ? undefined : false,
                    })}
                    aria-invalid={Boolean(error)}
                    hideIcon={hideIcon}
                    id={name}
                    onBlur={() => {
                        setHasFocus(false);
                        onBlur();
                    }}
                    onFocus={() => {
                        setHasFocus(true);
                    }}
                    disabled={disabled}
                />

                {hideIcon ? null : error ? <IconError /> : Boolean(inputValue) && <IconSuccess />}
            </InputWrapper>

            {!hideErrorMessage && error && <ErrorMessage>{error as string}</ErrorMessage>}
        </Column>
    );
};

export default Input;
