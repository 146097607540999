import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import CloseIconSvg from '$icons/iconography/close.svg';
import { Link } from '~/shared/components/Link';
import { StyledLink } from '~/shared/styles/Link';
import { breakpoints } from '~/theme';
import { NotificationProp } from './ProductNotifications';

type StyledNotificationProps = NotificationProp & {
    centered?: boolean;
    textColor?: string;
    closeNotification?: boolean;
    hasCloseIcon?: boolean;
};

export const CloseIcon = styled(CloseIconSvg)({
    cursor: 'pointer',
    justifySelf: 'start',
    alignSelf: 'center',
});

const slideDownAnimation = (topValue: string) =>
    keyframes({
        '0%': { top: 0 },
        '100%': { top: topValue },
    });

const slideUpAnimation = (topValue: string) =>
    keyframes({
        '0%': { top: topValue },
        '100%': { top: 0 },
    });
export const StyledNotification = styled.div<StyledNotificationProps>(
    ({ theme }) => ({
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridColumnGap: theme.space[2],
        position: 'relative',
        padding: theme.space[2],
    }),

    ifProp('centered', () => ({
        textAlign: 'center',

        '>*': {
            margin: '0 auto',
            width: 'fit-content',
        },
    })),

    ifProp('hasCloseIcon', () => ({
        [breakpoints.sm]: {
            gridTemplateColumns: '1fr 70px',
        },
    })),

    switchProp('variant', {
        high: ({ theme: { colors } }) => ({
            backgroundColor: colors.warning,
        }),
        low: ({ theme: { colors } }) => ({
            backgroundColor: colors.steelBlue,
        }),
        informative: ({ theme: { colors } }) => ({
            backgroundColor: colors.petrolGrey,
        }),
        positive: ({ theme: { colors } }) => ({
            backgroundColor: colors.success,
        }),
        campaign: ({ backgroundColor, textColor }) => ({
            backgroundColor,
            color: textColor,
            [`${StyledLink}`]: {
                color: textColor,
            },
        }),
        addedToCart: ({ theme: { colors, space, zIndices, easings }, closeNotification }) => ({
            '--menu-height': space[11],

            position: 'fixed',
            width: '100%',
            backgroundColor: colors.success,
            zIndex: zIndices.mainNavigationContent,
            animationName: closeNotification
                ? slideUpAnimation('var(--menu-height)')
                : slideDownAnimation('var(--menu-height)'),
            animationDuration: '1s',
            animationTimingFunction: easings.smoothEase,
            animationIterationCount: 1,
            willChange: 'top',
            top: closeNotification ? 0 : 'var(--menu-height)',

            [breakpoints.md]: {
                '--menu-height': space[12],
            },
        }),
    }),
);

export const StyledInfoText = styled.p(({ theme }) => ({
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.dog,
    letterSpacing: '-0.1px',
}));

export const StyledTitle = styled.p(({ theme }) => ({
    textTransform: 'uppercase',
    fontSize: theme.fontSizes.guinea,
    lineHeight: theme.lineHeights.dog,
    letterSpacing: '-0.1px',
    fontWeight: theme.fontWeights.medium,
}));

export const StyledInfoLink = styled(Link)<NotificationProp>(({ theme }) => ({
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.dog,
}));

export const StyledNotificationContent = styled.div({
    display: 'flex',
    flexDirection: 'column',
});

export const CustomLink = styled.a<{ color?: string }>(({ theme, color }) => ({
    color: color,
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.dog,
}));
