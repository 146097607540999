import { AnimatePresence } from 'framer-motion';
import { ReactNode } from 'react';
import { Error } from './styled';

interface Props {
    children: ReactNode;
}

const ErrorMessage = ({ children, ...rest }: Props) => (
    <AnimatePresence>
        {children && (
            <Error
                role="alert"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ ease: 'easeOut' }}
            >
                <div {...rest}>{children}</div>
            </Error>
        )}
    </AnimatePresence>
);

export default ErrorMessage;
