import PlaceIcon from '$icons/iconography/place.svg';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { LayoutRadioButton } from '~/shared/components/Form/components';
import { Link } from '~/shared/components/Link';

export const Container = styled.div(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 170px)',
    gridColumnGap: theme.space[2],
}));

export const Wrapper = styled.div<{ isActive: boolean; noStockDetails?: boolean }>(
    ({ theme }) => ({
        alignItems: 'center',
        borderRadius: 2,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        transition: 'background-color 200ms ease, border 200ms ease, box-shadow 200ms ease',

        '&:hover': {
            borderColor: theme.colors.brandBlue,
        },

        '> div:first-of-type': {
            flex: 'auto',
        },

        '> div:last-of-type': {
            flex: 'auto',
            minWidth: '45px',
        },
    }),

    ifProp('isActive', ({ theme }) => ({
        borderColor: theme.colors.brandBlue,
        backgroundColor: 'rgba(238,238,236, 0.7)', // theme.colors.grey30 70%,
    })),

    ifProp('noStockDetails', ({ theme }) => ({
        pointerEvents: 'none',

        '&:hover': {
            borderColor: theme.colors.grey50,
        },
    })),
);

export const StyledInfoText = styled.p(({ theme }) => ({
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.dog,
    color: theme.colors.grey70,
    letterSpacing: '-0.1px',
}));

export const SectionSelector = styled.div(({ theme }) => ({
    display: 'flex',
    width: '100%',
    gap: theme.space[2],
    alignItems: 'baseline',
    flexDirection: 'row-reverse',
}));

export const DeliveryText = styled.span(({ theme }) => ({
    fontSize: theme.fontSizes.mouse,
    lineHeight: theme.lineHeights.squirrel,
    overflowWrap: 'break-word',
    letterSpacing: '-0.429px',
}));

export const PickUpText = styled.span(({ theme }) => ({
    fontSize: theme.fontSizes.mouse,
    lineHeight: theme.lineHeights.squirrel,
    overflowWrap: 'break-word',
    gridColumn: '2 / 3',
}));

export const DeliveryPrice = styled.div(({ theme }) => ({
    fontSize: theme.fontSizes.mouse,
    lineHeight: theme.lineHeights.squirrel,
    flexShrink: 0,
    gridColumn: '2 / 3',
}));

export const DeliveryStatus = styled.span(({ theme }) => ({
    gridColumn: '1 / 2',
    gridRow: '1 / 2',
    display: 'grid',
    alignContent: 'start',
    paddingTop: theme.space[1],
}));

export const FreightTrigger = styled(Link)<{ hasPostalCode: boolean }>(
    ({ theme }) => ({
        appearance: 'none',
        backgroundColor: 'transparent',
        border: 0,
        cursor: 'pointer',
        height: 16,
        margin: 0,
        padding: 0,
        outline: 0,
        fontSize: theme.fontSizes.rat,
        fontWeight: theme.fontWeights.medium,
        lineHeight: theme.lineHeights.chicken,
    }),

    ifProp('hasPostalCode', ({ theme }) => ({
        marginRight: theme.space[2],
    })),
);

export const Loader = styled.div<{ isActive: boolean }>(
    ({ theme }) => ({
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 64,
        justifyContent: 'center',
        padding: theme.space[2],
        position: 'relative',

        svg: {
            fill: theme.colors.nightBlack,
        },
    }),

    ifProp('isActive', ({ theme }) => ({
        borderColor: theme.colors.brandBlue,
        backgroundColor: theme.colors.grey10,
    })),
);

export const RadioButton = styled(LayoutRadioButton)(({ theme }) => ({
    height: 16,
    width: 16,
    margin: `0 ${theme.space[2]} 0 0`,
}));

export const StyledPlaceIcon = styled(PlaceIcon)({ marginLeft: '-3px' });

export const StatusWrapper = styled.div(() => ({
    width: 175,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    height: 'min-content',
}));

export const StyledButton = styled.button(({ theme }) => ({
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    fontSize: theme.fontSizes.mouse,
    lineHeight: theme.lineHeights.squirrel,
    overflowWrap: 'break-word',
    display: 'inline',
    textDecoration: 'underline',
    padding: 0,
}));
