import styled from '@emotion/styled';
import { ProductNotification } from '~/features/ProductDetail';
import { Accordion } from '~/shared/components/Accordion';
import { StyledAccordionItem } from '~/shared/styles/Accordion';
import { breakpoints } from '~/theme';

export const Wrapper = styled.div(({ theme }) => ({
    '--columns': 1,
    '--gap': theme.space[5],
    display: 'grid',
    gridTemplateColumns: 'repeat(var(--columns), minmax(0, 1fr))',
    gap: 'var(--gap)',
    marginBottom: theme.space[6],

    [breakpoints.md]: {
        '--gap': theme.space[8],
        '--columns': 12,
    },
}));

export const Images = styled.div({
    [breakpoints.md]: {
        gridColumn: 'span 7',
    },
});

export const Information = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[4],
    marginBottom: theme.space[4],

    [breakpoints.md]: {
        gridColumn: 'span 5',
    },
    [breakpoints.lg]: {
        paddingLeft: theme.space[5],
    },
}));

export const ProductData = styled.div(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: `auto ${theme.space[5]} auto ${theme.space[5]} auto ${theme.space[3]} auto ${theme.space[4]} auto auto ${theme.space[7]}`,
    gridTemplateAreas:
        "'header' '.' 'variants' '.' 'price' '.' 'freight' '.' 'buy' '.' 'stock' '.' 'info'",

    [breakpoints.md]: {
        maxWidth: 460,
        gridTemplateRows: `auto ${theme.space[6]} auto ${theme.space[6]} auto ${theme.space[3]} auto ${theme.space[4]} auto auto auto ${theme.space[7]} `,
        position: 'sticky',
        top: 90,
    },
}));

export const GridArea = styled.div(
    ({
        area,
    }: {
        area: 'header' | 'variants' | 'price' | 'freight' | 'buy' | 'info' | 'stock';
    }) => ({
        gridArea: area,
    }),
);

export const DemoNotificationWrapper = styled.div(({ theme }) => ({
    margin: `0 0 ${theme.space[4]} 0`,
}));

export const AddToBasketWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.space[6],
    flexDirection: 'column',
}));

export const AccordionWrapper = styled(Accordion)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[2],
    marginBottom: theme.space[6],

    [breakpoints.md]: {
        marginBottom: theme.space[8],
    },

    [`${StyledAccordionItem}`]: {
        padding: theme.space[2],
    },
}));

export const BetaBanner = styled(ProductNotification)(({ theme }) => ({
    marginBottom: theme.space[3],
}));
