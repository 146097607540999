import styled from '@emotion/styled';
import { StyledResetButton } from '~/shared/styles/Button';
import { breakpoints } from '~/theme';

export const Wrapper = styled.div(({ theme }) => ({
    display: 'flex',
    borderRadius: 2,
    padding: 0,
    height: theme.space[6],
    '--icon-width': '44px',
    '--input-width': '77px',
}));

export const SmallWrapper = styled(Wrapper)(({ theme }) => ({
    height: theme.space[6],
    '--icon-width': '30px',
    '--input-width': '37px',

    [breakpoints.lg]: { display: 'flex', justifySelf: 'end' },
}));

export const MinusOne = styled(StyledResetButton)(({ theme }) => ({
    borderRadius: '2px 0 0 2px',
    color: theme.colors.nightBlack,
    display: 'flex',
    justifyContent: 'center',
    width: 'var(--icon-width)',
    border: `1px solid ${theme.colors.nightBlack}`,
    height: 'auto',

    '&:disabled': {
        cursor: 'default',
    },
}));

export const Amount = styled.input(({ theme }) => ({
    textAlign: 'center',
    border: `1px solid ${theme.colors.nightBlack}`,
    backgroundColor: 'transparent',
    borderLeft: 0,
    borderRight: 0,
    width: 'var(--input-width)',
    maxWidth: '100%',
    MozAppearance: 'textfield',
    margin: 0,
    borderRadius: 0,

    '&:focus': {
        outline: 'none',
    },

    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
    },
}));

export const AddOne = styled(StyledResetButton)(({ theme }) => ({
    borderRadius: '0 2px 2px 0',
    color: theme.colors.nightBlack,
    display: 'flex',
    justifyContent: 'center',
    width: 'var(--icon-width)',
    border: `1px solid ${theme.colors.nightBlack}`,
    height: 'auto',

    '&:disabled': {
        cursor: 'default',
    },
}));
