import dynamic from 'next/dynamic';
import GlobalModals from '~/features/GloabalModals/GlobalModals';

import { UmbracoTypes } from '~/lib/data-contract';
import { usePage } from '~/services';

const layouts = {
    standard: dynamic(() => import('./components/Standard')),
    checkout: dynamic(() => import('./components/Checkout')),
    newsPaper: dynamic(() => import('./components/NewsPaper')),
};

const getLayout = (pageType: UmbracoTypes.IPages['type'] | undefined) => {
    switch (pageType) {
        case UmbracoTypes.PageTypes.IP101CheckoutDeliveryPage:
        case UmbracoTypes.PageTypes.IP100CheckoutInformationPage:
            return layouts.checkout;

        case UmbracoTypes.PageTypes.IPNewsPaper:
            return layouts.newsPaper;

        default:
            return layouts.standard;
    }
};

export default function DynamicLayout({ children }: { children: React.ReactNode }) {
    const { data: page } = usePage<UmbracoTypes.IPages>();
    const Layout = getLayout(page?.type);

    return (
        <Layout>
            {children}
            <GlobalModals />
        </Layout>
    );
}
