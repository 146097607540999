import isPropValid from '@emotion/is-prop-valid';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import * as RadixAccordion from '@radix-ui/react-accordion';
import { breakpoints } from '~/theme';

type StyledAccordionContentProps = {
    disableAnimation?: boolean;
};

const openAnimation = keyframes({
    from: { height: 0, transform: 'translatey(-5px)', opacity: 0.5, padding: 0 },
    to: { height: 'var(--radix-accordion-content-height)' },
});

const closeAnimation = keyframes({
    from: { height: 'var(--radix-accordion-content-height)' },
    to: { height: 0, transform: 'translatey(-5px)', opacity: 0.5, padding: 0 },
});

export const StyledAccordionItem = styled(RadixAccordion.Item)(({ theme }) => ({
    width: '100%',
    border: `1px solid ${theme.colors.grey40}`,
    display: 'block',
    listStyle: 'none',

    '& + &': {
        marginTop: theme.space[1],
    },
}));

export const StyledAccordionTrigger = styled(RadixAccordion.Trigger)<{
    open: boolean;
}>(({ theme, open }) => ({
    display: 'grid',
    gap: theme.space[4],
    gridTemplateColumns: '1fr min-content',
    alignItems: 'center',
    padding: theme.space[2],
    fontSize: '12px',
    fontWeight: open ? theme.fontWeights.medium : theme.fontWeights.normal,

    // Button reset
    backgroundColor: 'transparent',
    border: 'none',
    width: '100%',
    cursor: 'pointer',
    textAlign: 'left',
    color: theme.colors.black,
}));

export const StyledAccordionIconWrapper = styled.div(({ theme }) => ({
    lineHeight: 0,
    /**
     * Handle icon animations through the data-state
     * @see https://www.radix-ui.com/docs/primitives/components/accordion#rotated-icon-when-open
     */
    // transitionDuration: '.2s',
    // '[data-state=open] &': {
    //     transform: 'scaleY(-1)'
    // },
    svg: {
        height: '1.5em',
        width: '1.5em',
        color: theme.colors.black,
    },
}));

export const StyledAccordionContent = styled.div(({ theme }) => ({
    width: '100%',
    maxWidth: '80%',
    padding: `0 ${theme.space[2]} ${theme.space[4]}`,
}));

export const StyledAccordionContainer = styled(RadixAccordion.Content, {
    /**
     * Prevent passing `disableAnimation` to `RadixAccordion.Content`.
     * This will cause an invalid prop error
     */
    shouldForwardProp: (prop) => isPropValid(prop as string) && prop !== 'disableAnimation',
})<StyledAccordionContentProps>(({ disableAnimation, theme }) => ({
    overflow: 'hidden',
    animationDuration: disableAnimation ? '0ms' : '250ms',
    animationTimingFunction: theme.easings.smoothEase,

    [breakpoints.reducedMotion]: {
        animationDuration: '0ms',
    },

    '&[data-state="open"]': {
        animationName: openAnimation,
    },
    '&[data-state="closed"]': {
        animationName: closeAnimation,
    },
}));

export const StyledDefaultIcon = styled.div({
    fontSize: '1.2em',
    path: {
        strokeWidth: '2px',
    },
});
