import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { ifProp, switchProp } from 'styled-tools';
import CloseIcon from '~/icons/iconography/close.svg';
import { StyledResetButton } from '~/shared/styles/Button';
import { breakpoints, breaks } from '~/theme';
import { ModalType } from './types';

export const Wrapper = styled.div(({ theme }) => ({
    position: 'fixed',
    inset: 0,
    zIndex: theme.zIndices.max,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const Content = styled.section<{ noPadding: boolean | undefined }>(
    ({ theme }) => ({
        padding: `0 ${theme.space[4]}`,
        overflowX: 'auto',
        scrollbarGutter: 'stable',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',

        [breakpoints.md]: {
            padding: `0 ${theme.space[5]}`,
        },
    }),

    ifProp('noPadding', () => ({
        padding: 0,

        [breakpoints.md]: {
            padding: 0,
        },
    })),
);

export const Backdrop = styled(motion.div)(({ theme }) => ({
    position: 'absolute',
    inset: 0,
    width: '100%',
    backgroundColor: theme.colors.nightBlack,
    userSelect: 'none',
}));

export const Main = styled(motion.main)<{
    modalType: ModalType;
    fullScreen?: boolean;
    noHeader?: boolean;
}>(
    ({ theme }) => ({
        backgroundColor: theme.colors.offWhite,
    }),
    switchProp('modalType', {
        fullscreen: ({ theme }) => ({
            position: 'relative',
            height: 'calc(100vh - 20px)',
            width: 'calc(100vw - 20px)',
            backgroundColor: theme.colors.grey10,
        }),
        powerstep: ({ theme }) => ({
            position: 'relative',
            height: '100dvh',
            width: '100vw',
            backgroundColor: theme.colors.offWhite,
            [breakpoints.sm]: {
                height: 'fit-content',
                width: '720px',
            },
            [`${Content}`]: {
                overflowX: 'visible',
            },
        }),
        miniModal: ({ theme }) => ({
            position: 'relative',
            width: '300px',
            backgroundColor: theme.colors.offWhite,
            [breakpoints.sm]: {
                height: 'fit-content',
                width: '450px',
            }
        }),

        sidebar: {
            top: 80,
            position: 'absolute',
            right: 0,
            bottom: 0,
            maxWidth: '100%',
            width: breaks.xxs,
            display: 'grid',
            gridTemplateRows: '75px 1fr',
            borderRadius: '16px 16px 0 0',

            [breakpoints.xxs]: {
                borderRadius: 0,
                top: 0,
            },
        },
    }),
    ifProp('fullScreen', () => ({
        borderRadius: 0,
        top: 0,

        [`${Content}`]: {
            padding: 0,

            [breakpoints.md]: {
                padding: 0,
            },
        },
    })),

    ifProp('powerstep', () => ({
        [`${Content}`]: {
            padding: 0,

            [breakpoints.md]: {
                paddingInline: 0,
            },
        },
    })),

    ifProp('noHeader', () => ({
        gridTemplateRows: '1fr',
    })),
);

export const Header = styled.header({
    position: 'relative',
});

export const Close = styled(StyledResetButton)(({ theme }) => ({
    position: 'absolute',
    top: theme.space[2],
    right: theme.space[2],
    display: 'flex',
    padding: theme.space[2],
}));

export const Icon = styled(CloseIcon)(({ theme }) => ({
    height: 25,
    width: 25,
    color: theme.colors.nightBlack,
}));
