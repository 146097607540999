import { forwardRef } from 'react';
import { useFormInput } from '../../hooks';
import ErrorMessage from '../ErrorMessage';
import { InputProps } from '../types';
import { InnerWrapper, Input, Text, Wrapper } from './styled';

const RadioButton = ({ children, name, required, value, ...rest }: InputProps) => {
    const { register, error } = useFormInput(name);

    return (
        <div>
            <LayoutRadioButton
                {...register(name, { required })}
                // ...rest after ...register to allow method overrides
                {...rest}
                aria-invalid={Boolean(error)}
                id={`${name}.${value}`}
                pattern={undefined}
                value={value}
            >
                {children}
            </LayoutRadioButton>

            {error && <ErrorMessage>{error as string}</ErrorMessage>}
        </div>
    );
};

export const LayoutRadioButton = forwardRef<HTMLInputElement, InputProps>(function Render(
    { children, required, ...rest },
    ref,
) {
    return (
        <label>
            <Wrapper className={rest.className}>
                <InnerWrapper>
                    <Input
                        {...rest}
                        ref={ref}
                        type="radio"
                        required={required as boolean}
                        pattern={undefined}
                    />
                    <Text disabled={rest.disabled}>{children}</Text>
                </InnerWrapper>
            </Wrapper>
        </label>
    );
});

export default RadioButton;
