import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';

type StyledHeaderProps = {
    showMeta: boolean;
};

export const StyledHeader = styled.div<StyledHeaderProps>(({ theme, showMeta }) => ({
    display: 'grid',
    alignItems: 'center',
    gap: theme.space[4],
    gridTemplateAreas: `
        'headline meta'
        'link meta'
    `,

    [breakpoints.sm]: {
        gridTemplateColumns: showMeta ? 'auto 1fr auto' : 'auto 1fr',
        gridTemplateAreas: showMeta ? `'headline link meta'` : `'headline link'`,
    },

    [`@container Carousel (width < 725px)`]: {
        gap: 0,
    },
}));

export const StyledArea = styled.div<{ area: 'headline' | 'link' | 'meta' }>(
    ({ area }) => ({
        gridArea: area,
    }),
    ifProp(
        { area: 'headline' },
        {
            justifySelf: 'start',
        },
    ),
    ifProp(
        { area: 'meta' },
        {
            display: 'flex',
            alignSelf: 'self-start',
            justifyContent: 'end',
        },
    ),
    ifProp(
        { area: 'link' },
        {
            justifySelf: 'end',
            [breakpoints.xs]: {
                justifySelf: 'start',
            },
        },
    ),
);
