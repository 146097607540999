import React, { RefObject, useCallback, useRef, useState } from 'react';
import ArrowLeft from '~/icons/iconography/arrow/left.svg';
import ArrowRight from '~/icons/iconography/arrow/right.svg';
import { UmbracoTypes } from '~/lib/data-contract';
import { MaxWidth } from '~/shared/components/MaxWidth';
import { ModuleContainer } from '../ModuleContainer';
import { ModuleHeader } from '../ModuleHeader';
import { Carousel, CarouselRef } from './components/Carousel';
import { StyledHeaderGutter, StyledPagination, StyledPaginationButton } from './styled';

type Props = {
    headline?: string;
    link?: UmbracoTypes.ILink;
    items?: React.ReactChild[];
    isProducts?: boolean;
    inputRef?: RefObject<HTMLDivElement>;
    wrapperRef?: React.RefObject<HTMLDivElement>;
};

/**
 * Renders items inside a carousel with pagination.
 * Ensures that the carousel is always rendered with the same width.
 *
 */
export const ModuleCarousel = ({
    headline,
    link,
    items = [],
    isProducts,
    wrapperRef,
    ...rest
}: Props) => {
    const [atEnd, setAtEnd] = useState(true);
    const [atBeginning, setAtBeginning] = useState(true);
    const carouselRef = useRef<CarouselRef>(null);

    // Purely used to get the perceived module width.
    // Cant calculate based on carousel since it's edge to edge
    const moduleWidthRef = useRef<HTMLDivElement>(null);

    const hidePagination = atBeginning && atEnd;

    const determinePagination = useCallback(() => {
        if (carouselRef.current) {
            const { scrollDistance, scrollLeft } = carouselRef.current;
            setAtBeginning(Math.floor(scrollLeft) <= 0);
            setAtEnd(Math.ceil(scrollLeft) >= scrollDistance);
        }
    }, []);

    // By default scrolls the entire width of the module
    const onClickHandlerGenerator = useCallback(
        (multiplier = 1) =>
            () => {
                const { current: carousel } = carouselRef;
                const { current: moduleWidth } = moduleWidthRef;

                if (carousel && moduleWidth) {
                    const distance = multiplier * moduleWidth.clientWidth;
                    carousel.scrollBy({ left: distance, behavior: 'smooth' });
                }
            },
        [],
    );

    return (
        <ModuleContainer fullWidth {...rest}>
            <MaxWidth key="header">
                <StyledHeaderGutter ref={moduleWidthRef}>
                    <ModuleHeader headlineText={headline} link={link} headlineVariant="display4">
                        {!hidePagination ? (
                            <StyledPagination>
                                <StyledPaginationButton
                                    aria-label="tidligere"
                                    onClick={onClickHandlerGenerator(-1)}
                                    disabled={atBeginning}
                                >
                                    <ArrowLeft />
                                </StyledPaginationButton>

                                <StyledPaginationButton
                                    aria-label="næste"
                                    onClick={onClickHandlerGenerator(1)}
                                    disabled={atEnd}
                                >
                                    <ArrowRight />
                                </StyledPaginationButton>
                            </StyledPagination>
                        ) : null}
                    </ModuleHeader>
                </StyledHeaderGutter>
            </MaxWidth>

            <Carousel
                key="carousel"
                wrapperRef={wrapperRef}
                items={items}
                ref={carouselRef}
                onResize={determinePagination}
                onScroll={determinePagination}
                isProducts={!!isProducts}
            />
        </ModuleContainer>
    );
};
