import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { ProductNotification, ProductPrice, ProductStatusInfo } from '~/features/ProductDetail';
import HazardSymbols from '~/features/ProductDetail/components/HazardSymbols';
import { ShopTypes } from '~/lib/data-contract';
import {
    useFrame,
    usePrice,
    useProduct,
    useStockDetails,
    useTranslations,
    useUserProfile,
} from '~/services';
import { ProductImage } from '~/shared/components/Image';
import PlaceholderImage from '~/shared/components/NewComponents/PlaceholderImage';
import { SkeletonLoader } from '~/shared/components/SkeletonLoader';
import { useConfig } from '~/shared/hooks';
import { getDifferenceInCalendarDays } from '~/shared/utils/helpers';
import { FreightTypes, useFreightType, usePostalCode } from '~/shared/utils/storage';
import { useModalFreight } from '~/state/useModalFreight';
import {
    hideQuickAddToBasketModal,
    setQuickFreightModalId,
    setQuickFreightSuccessCallback,
} from '~/state/useQuickAddToBasketState';
import { prefetchVariants } from '~/templates/pages/components/P50ProductDetailPage/utils/prefetchVariants';
import { theme } from '~/theme';
import { VariantSelector } from './VariantSelector';
import {
    BottomButton,
    BottomProductDataWrapper,
    CampaignNotificationWrapper,
    Container,
    DemoNotificationWrapper,
    GridArea,
    ImageBackground,
    ImageOverlay,
    ImageWrapper,
    ProductData,
    SkeletonWrapper,
    StyledAddToBasket,
    StyledInformation,
    StyledProductInfo,
    StyledProductLink,
} from './styled';
import { useSession } from 'next-auth/react';

type Props = {
    itemNo?: string;
};

export default function QuickAddToBasketInitial({ itemNo }: Props) {
    const router = useRouter();
    const [freightType] = useFreightType();
    const [postalCode] = usePostalCode();
    const { translate } = useTranslations();
    const { MAX_DELIVERY_DAYS_DIFFERENCE } = useConfig();

    const { data: brandUrl } = useFrame((frame) => frame.staticLinks?.brandPage?.url);

    const { data } = useSession();
    const { data: profile } = useUserProfile();
    const token = data?.accessToken ?? null;
    const customerId = profile?.customer?.id ?? '';

    const { activeWarehouseId } = useModalFreight();
    const [itemNumber, setItemNumber] = useState(itemNo);

    const { data: product, isLoading: productIsLoading } = useProduct(
        itemNumber ?? '',
        ({ product }) => product,
        (product) => prefetchVariants(product, itemNumber ?? ''),
    );

    const productIsObsolete = product?.item?.itemStatus === ShopTypes.ItemStatusValues.Obsolete;
    const { data: pricesData, isLoading: pricesIsLoading } = usePrice(
        itemNumber ?? '',
        !!itemNumber && !productIsObsolete,
        customerId,
        token,
    );
    const { data: stockDetails } = useStockDetails({
        enabled: !productIsObsolete,
        itemNo: product?.item?.itemNumber,
        postalCode,
    });

    const variantOptions = useMemo(
        () =>
            product?.variants?.filter(Boolean)?.map((option) => ({
                title: option.variantName,
                value: option.itemNumber,
                url: option.itemUrlSegment,
            })) || [],
        [product?.variants],
    );

    const info = product?.item ? product?.item : product;
    const name = `${info?.name ? `${info?.name} ` : ''}${info?.name2 ?? ''}`;
    const imageUrl =
        product?.item?.images?.map((item) => item.link)[0] ??
        product?.images?.map((item) => item.link)[0];
    const displayPrice = pricesData;

    const calculatedDeliveryDate =
        freightType && stockDetails?.[freightType]?.calculatedDeliveryDate;

    const productCannotShipInTime = calculatedDeliveryDate
        ? getDifferenceInCalendarDays(calculatedDeliveryDate) > MAX_DELIVERY_DAYS_DIFFERENCE
        : false;

    const productIsOutOfStock =
        (freightType && stockDetails?.[freightType]?.status === ShopTypes.StockStatus.OutOfStock) ||
        (!freightType &&
            stockDetails?.online?.status === ShopTypes.StockStatus.OutOfStock &&
            stockDetails?.pickup?.status === ShopTypes.StockStatus.OutOfStock);

    const possiblyDisplayProduct = useMemo(() => {
        return freightType === FreightTypes.Pickup
            ? stockDetails?.pickup?.warehouses?.find(({ id }) => id === activeWarehouseId)
                  ?.mightBeDisplayModel
            : stockDetails?.online?.mightBeDisplayModel;
    }, [stockDetails, freightType, activeWarehouseId]);

    return (
        <Container>
            {productIsLoading ? (
                <ProductSkeleton />
            ) : !product ? (
                <ProductNotification
                    content={translate('product.error.fetchingData')}
                    variant="high"
                />
            ) : (
                <>
                    <StyledInformation>
                        <ProductData>
                            <GridArea area="header">
                                <StyledProductLink
                                    href={product?.item?.itemRelativeUrl ?? ''}
                                    onClick={hideQuickAddToBasketModal}
                                >
                                    {imageUrl ? (
                                        <ImageBackground>
                                            <ImageWrapper>
                                                <ProductImage
                                                    src={imageUrl}
                                                    sizes="90px"
                                                    fill
                                                    alt={name}
                                                    style={{ objectFit: 'contain' }}
                                                />
                                            </ImageWrapper>
                                            <ImageOverlay />
                                        </ImageBackground>
                                    ) : (
                                        <PlaceholderImage size={90} />
                                    )}

                                    <StyledProductInfo
                                        brandUrl={brandUrl}
                                        product={product}
                                        campaigns={displayPrice?.activeCampaigns}
                                        hasComplementaryProducts={false}
                                    />
                                </StyledProductLink>
                            </GridArea>

                            {variantOptions.length < 2 ? null : (
                                <GridArea area="variants">
                                    <VariantSelector
                                        label={product?.item?.variantType}
                                        selectedVariantValue={itemNumber}
                                        variantList={variantOptions}
                                        setItemNumber={setItemNumber}
                                    />
                                </GridArea>
                            )}

                            <GridArea area="price">
                                {displayPrice?.activeCampaigns?.map((campaign, index) =>
                                    campaign?.title || campaign?.text || campaign?.url ? (
                                        <CampaignNotificationWrapper
                                            key={`${campaign.entityId}|${index}`}
                                        >
                                            <ProductNotification
                                                backgroundColor={campaign.labelColorCode}
                                                content={campaign.text}
                                                link={{
                                                    url: campaign.url || '',
                                                    text: campaign.linkText,
                                                    target: '_blank',
                                                }}
                                                textColor={campaign.fontColorCode}
                                                title={campaign.title}
                                                variant="campaign"
                                            />
                                        </CampaignNotificationWrapper>
                                    ) : null,
                                )}

                                {possiblyDisplayProduct ? (
                                    <DemoNotificationWrapper>
                                        <ProductNotification
                                            variant="low"
                                            content={translate(
                                                'product.error.possiblyDisplayProduct',
                                            )}
                                        />
                                    </DemoNotificationWrapper>
                                ) : null}

                                {productIsObsolete ? null : pricesIsLoading ? (
                                    <ProductPrice.Skeleton />
                                ) : !displayPrice ? (
                                    <ProductNotification
                                        content={translate('product.error.fetchingPrice')}
                                        variant="high"
                                    />
                                ) : (
                                    <ProductPrice priceItem={displayPrice} product={product} />
                                )}
                            </GridArea>
                            <GridArea area="buy">
                                {productIsObsolete ? (
                                    <ProductNotification
                                        variant="high"
                                        content={translate('product.obsolete')}
                                    />
                                ) : productIsOutOfStock ? (
                                    <ProductNotification
                                        variant="high"
                                        content={translate('product.soldOut')}
                                    />
                                ) : productCannotShipInTime ? (
                                    <ProductNotification
                                        variant="high"
                                        content={translate('product.delivery.time.exceeded')}
                                    />
                                ) : (
                                    <StyledAddToBasket
                                        itemNo={(itemNumber || itemNo) ?? ''}
                                        activeWarehouseId={activeWarehouseId}
                                        callbackHandler={setQuickFreightSuccessCallback}
                                        freightPriceHandler={setQuickFreightModalId}
                                        modalType={'quickAdd'}
                                        productStatus={product?.item?.itemStatus}
                                    />
                                )}
                            </GridArea>
                            <GridArea area="stock">
                                <ProductStatusInfo
                                    modalType={'quickAdd'}
                                    itemNo={itemNo}
                                    activeWarehouseId={activeWarehouseId}
                                    productStatus={product?.item?.itemStatus}
                                    setFreightHandler={setQuickFreightModalId}
                                    productIsOutOfStock={productIsOutOfStock}
                                />
                            </GridArea>

                            <GridArea area="info">
                                <BottomProductDataWrapper>
                                    {product?.item?.itemHazardSymbols?.length ? (
                                        <HazardSymbols
                                            product={product?.item}
                                            onClick={() =>
                                                router.push(`/${product.item?.itemNumber}`)
                                            }
                                        />
                                    ) : null}
                                </BottomProductDataWrapper>
                            </GridArea>
                        </ProductData>
                    </StyledInformation>
                    <NextLink href={product?.item?.itemRelativeUrl ?? ''} passHref legacyBehavior>
                        <BottomButton
                            as="a"
                            onClick={hideQuickAddToBasketModal}
                            variant="stroke"
                            color={theme.colors.nightBlack}
                        >
                            {translate('generic.readAboutProduct')}
                        </BottomButton>
                    </NextLink>
                </>
            )}
        </Container>
    );
}

export const ProductSkeleton = () => (
    <>
        <StyledInformation>
            <ProductData>
                <GridArea area="header">
                    <SkeletonWrapper>
                        <SkeletonLoader height={110} width={110} />
                        <SkeletonLoader height={55} isFullWidth />
                    </SkeletonWrapper>
                </GridArea>

                <GridArea area="variants">
                    <SkeletonLoader height={52} isFullWidth />
                </GridArea>

                <GridArea area="price">
                    <CampaignNotificationWrapper>
                        <SkeletonLoader height={90} isFullWidth />
                    </CampaignNotificationWrapper>

                    <DemoNotificationWrapper>
                        <SkeletonLoader height={42} isFullWidth />
                    </DemoNotificationWrapper>

                    <SkeletonWrapper style={{ justifyContent: 'space-between' }}>
                        <SkeletonLoader height={35} width={150} />
                        <SkeletonLoader height={35} width={100} />
                    </SkeletonWrapper>
                </GridArea>

                <GridArea area="freight">
                    <SkeletonLoader height={70} isFullWidth style={{ marginBottom: 5 }} />
                    <SkeletonLoader height={70} isFullWidth />
                </GridArea>

                <GridArea area="buy">
                    <SkeletonWrapper>
                        <SkeletonLoader height={45} isFullWidth />
                        <SkeletonLoader height={45} isFullWidth />
                    </SkeletonWrapper>
                </GridArea>

                <GridArea area="info">
                    <SkeletonWrapper>
                        <SkeletonLoader height={40} width={40} />
                        <SkeletonLoader height={40} width={40} />
                    </SkeletonWrapper>
                </GridArea>
            </ProductData>
        </StyledInformation>

        <SkeletonLoader height={45} isFullWidth style={{ marginBottom: 30 }} />
    </>
);
