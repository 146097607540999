import { useMedia } from 'react-use';
import { ShopTypes } from '~/lib/data-contract';
import { useTranslations } from '~/services';
import { SkeletonLoader } from '~/shared/components/SkeletonLoader';
import { useCurrency } from '~/shared/hooks';
import { queries } from '~/theme';
import { PriceRunner } from '../PriceRunner';
import { ProductNotification } from '../ProductNotifications/ProductNotifications';
import { BuyPrice, BuyPriceWrapper, NormalPrice, SalesUnit, Wrapper } from './styled';

type Props = {
    displayPriceExVat?: boolean;
    priceItem: ShopTypes.PriceWithCampaigns | undefined;
    product: ShopTypes.ProductQueryProduct | undefined;
    showOriginalPrice?: boolean;
};

const ProductPrice = ({
    displayPriceExVat = false,
    priceItem,
    product,
    showOriginalPrice = true,
}: Props) => {
    const { translate } = useTranslations();
    const formatCurrency = useCurrency();

    if (!priceItem?.price) {
        return (
            <ProductNotification
                variant="high"
                content={translate('product.delivery.time.exceeded')}
            />
        );
    }

    const { price, alternativePrice } = priceItem;
    const { salesUnitFormatted, alternativeSalesUnitFormatted, alternativeSalesUnitLookup } =
        product ?? {};

    const buyPrice = displayPriceExVat ? price?.price?.priceExVat : price?.price?.priceInclVat;
    const normalPrice = displayPriceExVat
        ? price?.originalPrice?.priceExVat
        : price?.originalPrice?.priceInclVat;
    const altPrice = displayPriceExVat
        ? alternativePrice?.price?.priceExVat
        : alternativePrice?.price?.priceInclVat;
    const hasAlternativeSalesUnit = !!alternativePrice && altPrice !== buyPrice;

    const salesUnit = translate('product.productCardSalesUnit').replace(
        '[SALES_UNIT]',
        salesUnitFormatted || '',
    );
    const alternativeSalesUnit = hasAlternativeSalesUnit
        ? translate('product.productCardAlternativeSalesUnit')
              .replace(
                  '[ALTERNATIVE_SALES_UNIT]',
                  (alternativeSalesUnitFormatted || alternativeSalesUnitLookup)!,
              )
              .replace(
                  '[ALTERNATIVE_SALES_PRICE]',
                  formatCurrency(
                      displayPriceExVat
                          ? alternativePrice!.price?.priceExVat
                          : alternativePrice!.price?.priceInclVat,
                  ),
              )
        : null;

    const normalAlternativeSalesUnit =
        hasAlternativeSalesUnit && alternativePrice!.originalPrice
            ? translate('product.productCardAlternativeSalesUnit')
                  .replace(
                      '[ALTERNATIVE_SALES_UNIT]',
                      (alternativeSalesUnitFormatted || alternativeSalesUnitLookup)!,
                  )
                  .replace(
                      '[ALTERNATIVE_SALES_PRICE]',
                      formatCurrency(
                          displayPriceExVat
                              ? alternativePrice!.originalPrice?.priceExVat
                              : alternativePrice!.originalPrice?.priceInclVat,
                      ),
                  )
            : null;

    return (
        <Wrapper>
            <BuyPriceWrapper>
                <BuyPrice variant="display2" as="p" discount={!!normalPrice && !!showOriginalPrice}>
                    {formatCurrency(buyPrice)}

                    <SalesUnit
                        variant="display5"
                        as="span"
                        discount={!!normalPrice && !!showOriginalPrice}
                    >
                        {salesUnit}
                        {alternativeSalesUnit}
                        {displayPriceExVat
                            ? ' ' + translate('product.productCardSalesExVAT')
                            : translate('product.productCardSalesUnitFreight')}
                    </SalesUnit>
                </BuyPrice>

                {product ? <PriceRunner product={product} /> : null}
            </BuyPriceWrapper>

            {normalPrice && showOriginalPrice ? (
                <NormalPrice as="del" variant="display4">
                    {formatCurrency(normalPrice)}

                    <SalesUnit variant="display5" as="span">
                        {salesUnit}
                        {normalAlternativeSalesUnit}
                        {displayPriceExVat
                            ? ' ' + translate('product.productCardSalesExVAT')
                            : translate('product.productCardSalesUnitFreight')}
                    </SalesUnit>
                </NormalPrice>
            ) : null}
        </Wrapper>
    );
};

const Skeleton = () => {
    const isDesktop = useMedia(queries.lg, false);

    return <SkeletonLoader height={isDesktop ? 40 : 35} width={150} />;
};

ProductPrice.Skeleton = Skeleton;
export default ProductPrice;
