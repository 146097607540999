import React, { useMemo } from 'react';
import NextLink from 'next/link';
import { ShopTypes } from '~/lib/data-contract';
import { useTranslations } from '~/services';
import { SkeletonLoader } from '~/shared/components/SkeletonLoader';
import {
    Brand,
    ComplementaryProductsLink,
    Header,
    ReadMore,
    Sku,
    SkuContainer,
    Splash,
    SplashesWrapper,
    StyledBrandLink,
    StyledLink,
    Text,
    Wrapper,
} from './styled';

type Props = {
    brandUrl: string | undefined;
    campaigns: ShopTypes.ActiveCampaignDetails[] | undefined;
    product: ShopTypes.ProductQueryProduct;
    onReadMoreClick?: (event: React.MouseEvent) => void;
    onComplementaryProductsClick?: (event: React.MouseEvent) => void;
    hasComplementaryProducts: boolean;
};

const WEBSITE_URL = process.env.NEXT_PUBLIC_WEBSITE_URL;

export default function ProductInfo({
    brandUrl,
    product,
    onReadMoreClick,
    onComplementaryProductsClick,
    hasComplementaryProducts,
    campaigns,
}: Props) {
    const { translate } = useTranslations();
    const info = product?.item || product;
    const productName = `${info?.name ? `${info?.name} ` : ''}${info?.name2 ?? ''}`;
    const productSku = product.item?.itemNumber || product.primaryItemNumber;
    const description = product.shortDescription ?? product.longDescription;
    const brandHref =
        WEBSITE_URL && brandUrl
            ? WEBSITE_URL + brandUrl + '/' + product.brand?.replace(' ', '-').toLowerCase()
            : null;

    const campaignSplashes = useMemo(() => {
        if (!campaigns) {
            return;
        }
        const splashMap = new Map<string, ShopTypes.ActiveCampaignDetails>();
        campaigns.forEach((campaign) => {
            if (splashMap.has(String(campaign.entityId)) ?? !campaign.labelDescription) {
                return;
            }
            splashMap.set(String(campaign.entityId), campaign);
        });
        return Array.from(splashMap.values());
    }, [campaigns]);

    return (
        <Wrapper>
            {campaigns ? (
                <SplashesWrapper>
                    {campaignSplashes?.map((campaign) =>
                        campaign.url && campaign.labelDescription ? (
                            <Splash
                                key={campaign.entityId}
                                style={{
                                    backgroundColor: `#${campaign.labelColorCode}`,
                                    color: `#${campaign.fontColorCode}`,
                                }}
                            >
                                {campaign.url?.startsWith('/') ? (
                                    <StyledLink href={campaign.url} target="_self">
                                        {campaign.labelDescription}
                                    </StyledLink>
                                ) : (
                                    <a
                                        id="campaignSplash"
                                        style={{
                                            color: `#${campaign.fontColorCode}`,
                                            backgroundColor: `#${campaign.labelColorCode}`,
                                        }}
                                        href={campaign.url}
                                        target="_blank"
                                    >
                                        {campaign.labelDescription}
                                    </a>
                                )}
                            </Splash>
                        ) : null,
                    )}
                </SplashesWrapper>
            ) : null}

            {productName ? (
                <Header as="h1" variant="display3">
                    {productName}
                </Header>
            ) : null}

            {product.brand ? (
                brandHref ? (
                    <NextLink href={brandHref} passHref legacyBehavior>
                        <StyledBrandLink animation="reverse">{product.brand}</StyledBrandLink>
                    </NextLink>
                ) : (
                    <Brand as="h2" variant="paragraph">
                        {product.brand}
                    </Brand>
                )
            ) : null}

            <SkuContainer>
                {productSku ? (
                    <Sku as="p" variant="paragraph">
                        {productSku}
                    </Sku>
                ) : null}
            </SkuContainer>

            {description ? (
                <>
                    <Text as={'p'} variant="paragraph">
                        {description}
                    </Text>

                    <ReadMore href="#description" onClick={onReadMoreClick}>
                        {translate('product.readMore')}
                    </ReadMore>
                </>
            ) : null}

            {hasComplementaryProducts ? (
                <ComplementaryProductsLink
                    href="#complementary"
                    onClick={onComplementaryProductsClick}
                >
                    {translate('product.viewComplementaryProducts')}
                </ComplementaryProductsLink>
            ) : null}
        </Wrapper>
    );
}

const Skeleton = () => (
    <>
        <SkeletonLoader height={16} width={60} isFullWidth style={{ marginBottom: 10 }} />
        <SkeletonLoader height={35} width={180} isFullWidth style={{ marginBottom: 5 }} />
        <SkeletonLoader height={35} width={250} style={{ marginBottom: 25 }} />

        <SkuContainer>
            <SkeletonLoader height={15} width={100} style={{ marginBottom: 5 }} />
            <SkeletonLoader height={15} width={100} style={{ marginBottom: 5 }} />
        </SkuContainer>
        <SkeletonLoader height={100} width={300} style={{ marginBottom: 5 }} />
    </>
);

ProductInfo.Skeleton = Skeleton;
