import NextLink from 'next/link';
import { useRef } from 'react';
import { useBasket, useFrame, useTranslations } from '~/services';
import { Button } from '~/shared/components/Button';
import { Modal } from '~/shared/components/Modal';
import { closePowerStepModal, usePowerStepState } from '~/state/usePowerStepState';
import PowerStepCarousel from './components/Carousel/Carousel';
import { BasketItemProps, ModalBasketItem } from './components/ModalBasketItem/ModalBasketItem';
import { ButtonWrapper, PageTitle, ProductCardWrapper, StyledButton, Wrapper } from './styled';

export default function PowerStep() {
    const { data: basket } = useBasket();
    const { data: frame } = useFrame();
    const { translate } = useTranslations();
    const wrapperRef = useRef<HTMLDivElement>(null);

    const { isOpen, itemNumber, warehouseId } = usePowerStepState((state) => ({
        isOpen: state.isOpenPowerStep,
        itemNumber: state.powerStepId,
        warehouseId: state.warehouseId,
    }));

    const targetItem = basket?.contexts
        ?.flatMap((context) =>
            context?.freights?.flatMap((freight) =>
                freight?.brands?.flatMap((brand) =>
                    brand?.items?.map((item) => ({
                        ...item,
                        brand: brand?.name,
                    })),
                ),
            ),
        )
        ?.find((item) => {
            if (warehouseId) {
                return (
                    item?.wareHouseId === warehouseId &&
                    !item?.isBundledItem &&
                    (item?.itemNumber === itemNumber || item?.bundleId === itemNumber)
                );
            } else if (!!item?.bundleId && !item.isBundledItem) {
                return item?.bundleId === itemNumber;
            } else if (!item?.isBundledItem) {
                return item?.itemNumber === itemNumber;
            }
        });

    return (
        <Modal
            isOpen={isOpen}
            handleClose={closePowerStepModal}
            noHeader={false}
            type="powerstep"
            fullScreen={false}
            noPadding
        >
            <Wrapper ref={wrapperRef}>
                <PageTitle variant="display3" as="h2">
                    {translate('generic.addedToBasket')}
                </PageTitle>
                <ModalBasketItem
                    key={`${itemNumber}`}
                    item={targetItem as BasketItemProps['item']}
                />
                <ButtonWrapper>
                    <StyledButton variant="stroke" onClick={closePowerStepModal}>
                        {translate('generic.continueShopping')}
                    </StyledButton>
                    <NextLink
                        href={frame?.staticLinks?.basketPage?.url ?? '/'}
                        legacyBehavior
                        passHref
                    >
                        <Button as="a" variant="fill" onClick={closePowerStepModal}>
                            {translate('generic.goToBasket')}
                        </Button>
                    </NextLink>
                </ButtonWrapper>
                <ProductCardWrapper>
                    <PowerStepCarousel
                        productId={targetItem?.itemNumber ?? ''}
                        wrapperRef={wrapperRef}
                    />
                </ProductCardWrapper>
            </Wrapper>
        </Modal>
    );
}
