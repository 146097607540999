import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { UmbracoTypes } from '~/lib/data-contract';
import { ModuleContainerProps } from './ModuleContainer';

type SpacingValues = keyof Theme['space'] | undefined;

const spacings: Record<UmbracoTypes.ModuleSpace, SpacingValues> = {
    small: '2',
    default: '4',
    none: undefined,
};

const spacingValue = (spacing: UmbracoTypes.ModuleSpace, theme: Theme) => {
    const value = spacings[spacing];
    return value ? theme.space[value] : 0;
};

export const StyledModule = styled.section<ModuleContainerProps>(
    ({ theme, spacingTop = 'none', spacingBottom = 'none' }) => ({
        paddingTop: spacingValue(spacingTop, theme),
        paddingBottom: spacingValue(spacingBottom, theme),
        margin: '0 auto',
        width: '100%',
        [`@container Carousel (width < 725px)`]: {
            padding: '0',
            paddingTop: theme.space[3],
            paddingBottom: theme.space[5],
        },
    }),
    ifProp('backgroundColor', ({ backgroundColor }) => ({
        backgroundColor,
    })),
);
