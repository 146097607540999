import React from 'react';
import { breakpoints, theme } from '$theme';
import { StyledSpacer } from './styled';

type SpacingKeys = keyof typeof theme.space;
type BreakpointKeys = keyof typeof breakpoints;

type BreakpointSpacingPair = Record<string, SpacingKeys>;

export interface SpacerProps {
    size: SpacingKeys;
    hideFrom?: BreakpointKeys;
    customFrom?: BreakpointSpacingPair;
    customUntil?: BreakpointSpacingPair;
}

/**
 * Vertical spacing between elements.
 * Uses the theme.space object to determine the spacing.
 *
 * @example
 * <Spacer size="xl" hideFrom="md" />
 *
 * @example
 * <Spacer customFrom={{ '500px': 'xl', '1000px': 'sm' }} />
 */
export const Spacer = (props: SpacerProps) => <StyledSpacer aria-hidden {...props} />;

export default Spacer;
