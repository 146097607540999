import { ReactNode, RefObject } from 'react';
import { UmbracoTypes } from '~/lib/data-contract';
import { Gutter } from '~/shared/components/Gutter';
import { MaxWidth } from '~/shared/components/MaxWidth';
import { useModule } from '../../hooks/useModule';
import { useModuleTheme } from '../../hooks/useModuleTheme';
import { StyledModule } from './styled';

export type ModuleContainerProps = {
    /**
     * Adds a solid background color to the entire module space.
     * Edge to edge.
     */
    backgroundColor?: string;

    spacingTop?: UmbracoTypes.ModuleSpacing['spacingTop'];
    spacingBottom?: UmbracoTypes.ModuleSpacing['spacingBottom'];
    inputRef?: RefObject<HTMLDivElement>;

    /**
     * Prevents capping the width to the max content width
     */
    fullWidth?: boolean;

    /**
     * Add side gutters to the module. Useful if the content
     * is not intended to go full width on mobile. eg. text modules
     */
    hasGutter?: boolean;
    children: ReactNode;
    className?: string;
    id?: string;
};

export const ModuleContainer = ({
    fullWidth,
    children,
    inputRef,
    spacingTop,
    spacingBottom = 'default',
    hasGutter,
    className,
    backgroundColor: initialBackgroundColor,
    id,
}: ModuleContainerProps) => {
    const { index } = useModule();
    const { backgroundColor } = useModuleTheme({ backgroundColor: initialBackgroundColor });
    const content = hasGutter ? <Gutter>{children}</Gutter> : children;

    return (
        <StyledModule
            id={id}
            className={className}
            backgroundColor={backgroundColor}
            ref={inputRef}
            spacingBottom={spacingBottom}
            spacingTop={spacingTop ? spacingTop : index === 0 ? 'small' : spacingBottom}
        >
            {fullWidth ? content : <MaxWidth>{content}</MaxWidth>}
        </StyledModule>
    );
};
