import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';

interface Flex {
    align?: 'center' | 'end' | 'start' | 'baseline';
    flex?: string;
    flow?: 'column' | 'row';
    justify?: 'around' | 'between' | 'center' | 'end' | 'evenly' | 'start';
}

export const Flex = styled.div<Flex>(
    {
        display: 'flex',
    },
    ifProp('flex', ({ flex }) => ({
        flex: flex,
    })),
    switchProp('flow', () => ({
        column: { flexFlow: 'column' },
        row: { flexFlow: 'row' },
    })),
    switchProp('align', () => ({
        center: { alignItems: 'center' },
        end: { alignItems: 'end' },
        start: { alignItems: 'start' },
        baseline: { alignItems: 'baseline' },
    })),
    switchProp('justify', () => ({
        around: { justifyContent: 'space-around' },
        center: { justifyContent: 'center' },
        between: { justifyContent: 'space-between' },
        end: { justifyContent: 'end' },
        evenly: { justifyContent: 'space-evenly' },
        start: { justifyContent: 'start' },
    })),
);
