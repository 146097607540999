import NextLink from 'next/link';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ShopTypes } from '~/lib/data-contract';
import { useProduct, useResponseHandler, useSetBasketItem, useTranslations } from '~/services';
import { ErrorMessage, Form } from '~/shared/components/Form';
import { ImageOverlay, ProductImage } from '~/shared/components/Image';
import {
    SelectAmount,
    SelectAmountForm,
    SelectAmountTypeFormFields,
} from '~/shared/components/SelectAmount';
import { useCurrency } from '~/shared/hooks';
import {
    ItemAmountSelector,
    ItemImageContainer,
    ItemImageWrapper,
    ItemName,
    ItemNumber,
    ItemPrice,
    ItemPricePerItem,
    ItemQuantityContainer,
    ItemType,
    ItemTypeVariant,
    ItemVariant,
    ItemWrapper,
    LineItem,
    PriceWrapper,
} from './styled';
import PlaceholderImage from '~/shared/components/NewComponents/PlaceholderImage';

export type BasketItemProps = {
    item: ModalBasketItemProps;
};

type ModalBasketItemProps = ShopTypes.BasketItemResponse & {
    brand: string;
};
export function ModalBasketItem({ item }: BasketItemProps) {
    const formatCurrency = useCurrency();
    const { translate } = useTranslations();
    const { mutate: setBasketItem, isLoading } = useSetBasketItem();
    const name = `${item?.name ? `${item?.name} ` : ''}${item?.name2 ?? ''}`;
    const { data: productData } = useProduct(item?.itemNumber ?? '', ({ product }) => product);

    const methods = useForm<SelectAmountForm>({
        defaultValues: { [SelectAmountTypeFormFields.Quantity]: item?.quantity },
    });

    const [basketValidationError, clearBasketValidationError, basketValidationHandler] =
        useResponseHandler<ShopTypes.BasketValidationError>({
            statusCodes: [400],
            onError: () => {
                methods?.setValue(SelectAmountTypeFormFields.Quantity, item?.quantity);
            },
        });

    const salesUnit = translate('basket.itemSalesUnit').replace(
        '[SALES_UNIT]',
        item?.salesUnit || '',
    );

    const imageLink = item?.image?.link ?? productData?.images?.[0]?.link;

    // Update form value on item change
    useEffect(() => {
        methods?.setValue(SelectAmountTypeFormFields.Quantity, item?.quantity);
    }, [item?.quantity, methods]);

    //  Call BE on form value update
    useEffect(() => {
        const subscription = methods?.watch((values) => {
            const { quantity } = values;
            const shouldUpdate = quantity && quantity !== item?.quantity;

            if (shouldUpdate) {
                clearBasketValidationError();
                setBasketItem(
                    {
                        itemNo: item?.itemNumber,
                        quantity,
                        warehouseId: item?.wareHouseId,
                        isBundle: !!productData?.isBundle,
                    },
                    basketValidationHandler,
                );
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [clearBasketValidationError, item, methods, setBasketItem, basketValidationHandler]);

    return (
        <ItemWrapper>
            <LineItem>
                <ItemImageContainer>
                    <ItemImageWrapper>
                        {imageLink ? (
                            <ProductImage
                                src={imageLink}
                                alt={name}
                                title={name}
                                style={{ objectFit: 'contain' }}
                                width={80}
                                height={80}
                            />
                        ) : (
                            <PlaceholderImage />
                        )}
                        <ImageOverlay />
                    </ItemImageWrapper>
                </ItemImageContainer>

                <ItemType>{item?.brand}</ItemType>

                <NextLink href={item?.itemUrl || ''} passHref legacyBehavior>
                    <ItemName animation="reverse">{name}</ItemName>
                </NextLink>

                <ItemVariant>
                    {item?.variantType && item?.variantName ? (
                        <>
                            <ItemTypeVariant>{item?.variantType}: </ItemTypeVariant>
                            {item?.variantName}
                        </>
                    ) : null}
                </ItemVariant>

                <ItemNumber>
                    {translate('generic.itemNumber')} {item?.itemNumber}
                </ItemNumber>

                <ItemQuantityContainer>
                    <ItemAmountSelector>
                        <Form methods={methods} onSubmit={() => undefined}>
                            <SelectAmount
                                debounce
                                disabled={isLoading}
                                name={SelectAmountTypeFormFields.Quantity}
                                smallSize
                            />
                        </Form>
                    </ItemAmountSelector>

                    <ItemPricePerItem>
                        {item?.price ? formatCurrency(item?.price.priceInclVat) : formatCurrency(0)}{' '}
                        {salesUnit}
                    </ItemPricePerItem>

                    <PriceWrapper>
                        <ItemPrice>
                            {item?.subTotal
                                ? formatCurrency(item?.subTotal.priceInclVat)
                                : formatCurrency(0)}
                        </ItemPrice>
                    </PriceWrapper>
                </ItemQuantityContainer>

                <ErrorMessage>
                    {basketValidationError || item?.validationError
                        ? translate(
                              (basketValidationError || item?.validationError)?.validationText
                                  ?.name,
                              (basketValidationError || item?.validationError)?.validationText
                                  ?.values,
                          )
                        : null}
                </ErrorMessage>
            </LineItem>
        </ItemWrapper>
    );
}
