import GHS01 from '~/icons/hazard/GHS01.svg';
import GHS02 from '~/icons/hazard/GHS02.svg';
import GHS03 from '~/icons/hazard/GHS03.svg';
import GHS04 from '~/icons/hazard/GHS04.svg';
import GHS05 from '~/icons/hazard/GHS05.svg';
import GHS06 from '~/icons/hazard/GHS06.svg';
import GHS07 from '~/icons/hazard/GHS07.svg';
import GHS08 from '~/icons/hazard/GHS08.svg';
import GHS09 from '~/icons/hazard/GHS09.svg';

export const hazardSymbols = {
    GHS01,
    GHS02,
    GHS03,
    GHS04,
    GHS05,
    GHS06,
    GHS07,
    GHS08,
    GHS09,
};
