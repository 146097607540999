import { useTheme } from '@emotion/react';
import { useMemo } from 'react';
import { ButtonProps } from '~/shared/components/Button';
import { getTextColor, nightBlack, PotentialThemeColorType, usePotentialThemeColor } from '~/theme';

type useModuleThemeProps = {
    backgroundColor?: string | PotentialThemeColorType;

    // If no text color is provided, use the text color of the background color
    textColor?: string | PotentialThemeColorType;

    buttonVariant?: ButtonProps['variant'];
};

/**
 * Handle logic relatated to module theming.
 * Currently mostly used to set button and text colors based on background color.
 */
export const useModuleTheme = ({
    backgroundColor,
    textColor,
    buttonVariant = 'stroke',
}: useModuleThemeProps) => {
    const { colors } = useTheme();

    // Fallback to default page background color
    const backgroundColorValue = usePotentialThemeColor(backgroundColor || '');

    // If no text color is provided, use the text color of the background color
    const textColorValue = usePotentialThemeColor(textColor || '');

    // Get text color from background color if not set
    const safeTextColorValue = useMemo(
        () => textColorValue || getTextColor(backgroundColorValue || colors.grey10),
        [backgroundColorValue, textColorValue, colors]
    );

    // Currently hardcoded to only serve white on selected colors
    const buttonColor = useMemo<ButtonProps['color']>(() => {
        const { brandBlue, offWhite } = colors;

        const primaryColor = buttonVariant === 'fill' ? brandBlue : nightBlack;
        const useWhiteButton = safeTextColorValue === offWhite;

        return useWhiteButton ? offWhite : primaryColor;
    }, [safeTextColorValue, colors, buttonVariant]);

    return {
        backgroundColor: backgroundColorValue,
        textColor: safeTextColorValue,
        buttonColor,
        buttonVariant,
    };
};
