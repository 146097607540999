import { Link } from '~/shared/components/Link';
import { NotificationProp, ProductNotification } from './ProductNotifications';

export const FullLinkNotification = (props: NotificationProp) => {
    const { link, ...newProps } = props;

    const linkRel = link?.target === '_blank' ? 'noopener noreferrer' : '';

    return link?.url ? (
        <Link href={link.url} target={link.target} rel={linkRel}>
            <ProductNotification {...newProps} />
        </Link>
    ) : null;
};
