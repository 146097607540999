import { Modal } from '~/shared/components/Modal';
import useQuickModalState, { hideQuickAddToBasketModal } from '~/state/useQuickAddToBasketState';
import QuickAddProduct from './components/QuickAddProduct';
import { resetQuickAdd, setItemNo } from '~/state/useModalFreight';

export default function QuickAddToBasket() {
    const { isOpen } = useQuickModalState((state) => ({
        isOpen: state.isOpen,
    }));

    return (
        <Modal
            handleClose={() => {
                hideQuickAddToBasketModal();
                resetQuickAdd();
                setItemNo('');
            }}
            isOpen={isOpen}
        >
            <QuickAddProduct />
        </Modal>
    );
}
