import { Text, TextProps } from '$shared/components/Text';
import { useModule } from '../../hooks/useModule';

type HeadlineProps = TextProps & {
    children?: React.ReactNode;
};

export const Headline = (props: HeadlineProps) => {
    const { index } = useModule();

    // Cast to 0 if undefined or null
    const safeIndex = Number(index) || 0;
    const headlineTag = safeIndex === 0 ? 'h1' : 'h2';

    return <Text as={props.as ?? headlineTag} {...props} />;
};
