import NextLink from 'next/link';
import React from 'react';
import { UmbracoTypes } from '~/lib/data-contract';
import { Link } from '~/shared/components/Link';
import { Spacer } from '~/shared/components/Spacer';
import { TextVariants } from '~/shared/components/Text';
import { Headline } from '../Headline';
import { StyledArea, StyledHeader } from './styled';

type ModuleHeaderProps = {
    headlineText?: string;
    headlineVariant?: TextVariants;
    link?: UmbracoTypes.ILink;
    children?: React.ReactNode;
};

export const ModuleHeader = ({
    headlineText,
    headlineVariant = 'display1',
    link,
    children,
}: ModuleHeaderProps) => {
    return (
        <>
            <StyledHeader showMeta={!!children}>
                {headlineText ? (
                    <StyledArea area="headline">
                        <Headline variant={headlineVariant}>{headlineText}</Headline>
                    </StyledArea>
                ) : null}
                {link?.url ? (
                    <StyledArea area="link">
                        <NextLink href={link.url} passHref legacyBehavior>
                            <Link target={link.target}>{link.text}</Link>
                        </NextLink>
                    </StyledArea>
                ) : null}
                {children ? <StyledArea area="meta">{children}</StyledArea> : null}
            </StyledHeader>
            <Spacer size="5" />
        </>
    );
};
