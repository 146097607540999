import { create } from 'zustand';

type ModalState = {
    isOpenModal: boolean;
    isDisabled: boolean;
    itemNo: string;
    activeWarehouseId: number | undefined;
    isQuickAdd: boolean;
    errorMessages: string;
};

export const useModalFreight = create<ModalState>(() => ({
    isOpenModal: false,
    isDisabled: false,
    itemNo: '',
    activeWarehouseId: undefined,
    isQuickAdd: false,
    errorMessages: '',
}));

export function openModalFreight() {
    useModalFreight.setState({
        isOpenModal: true,
    });
}

export function closeModalFreight() {
    useModalFreight.setState({
        isOpenModal: false,
    });
}

export function isButtonDisabled(disabled: boolean) {
    useModalFreight.setState({
        isDisabled: disabled,
    });
}

export function setItemNo(itemNo: string) {
    useModalFreight.setState({
        itemNo,
        errorMessages: '',
    });
}

export function setActiveSetWarehouseId(activeWarehouseId: number | undefined) {
    useModalFreight.setState({
        activeWarehouseId,
    });
}

export function resetQuickAdd() {
    useModalFreight.setState({
        isQuickAdd: false,
        errorMessages: '',
    });
}

export const openQuickAddModal = () => {
    useModalFreight.setState({
        isQuickAdd: true,
    });
};

export const errorMessages = (errorMessages: string) => {
    useModalFreight.setState({
        errorMessages,
    });
};
