import { useState, useEffect } from 'react';

export default function useInputHighlight(hasValue: boolean) {
    const [hasFocus, setHasFocus] = useState(false);
    const [isHighlighted, setIsHighlighted] = useState(hasValue);

    useEffect(() => {
        setIsHighlighted(Boolean(hasValue) || hasFocus);
    }, [hasFocus, hasValue]);

    return { isHighlighted, setHasFocus };
}
