import { useFormInput } from '../../hooks';
import ErrorMessage from '../ErrorMessage';
import { InputProps } from '../types';
import { Input, Wrapper } from './styled';

export default function CheckBox({ children, name, required, ...rest }: InputProps) {
    const { register, error } = useFormInput(name);

    return (
        <div>
            <label>
                <Wrapper>
                    <Input
                        {...register(name, { required })}
                        {...rest}
                        aria-invalid={Boolean(error)}
                        pattern={undefined}
                        type="checkbox"
                    />

                    {children}
                </Wrapper>
            </label>

            {error && <ErrorMessage>{error as string}</ErrorMessage>}
        </div>
    );
}
