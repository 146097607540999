import styled from '@emotion/styled';
import { theme, breakpoints } from '$theme';
import { SpacerProps } from './Spacer';

/**
 * @TODO should folow styled convention with objects
 */
export const StyledSpacer = styled.div<SpacerProps>`
    width: 100%;
    height: ${({ size }) => theme.space[size]};

    ${({ hideFrom }) =>
        hideFrom &&
        `
        ${breakpoints[hideFrom]} {
            display: none;
        }
    `}

    ${({ customFrom }) =>
        customFrom &&
        `
        ${Object.entries(customFrom)
            .map(
                ([key, value]) => `
            @media (min-width: ${key}) {
                height: ${theme.space[value]};
            }
        `
            )
            .join('')}
    `}

    ${({ customUntil }) =>
        customUntil &&
        `
        ${Object.entries(customUntil)
            .map(
                ([key, value]) => `
            @media (max-width: ${key}) {
                height: ${theme.space[value]};
            }
        `
            )
            .join('')}
    }
    `}
`;
