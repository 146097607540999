import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { LayoutRadioButton } from '~/shared/components/Form/components';
import { ProductNotification } from '../ProductNotifications/ProductNotifications';
import { StockStatus } from '~/shared/components/StockStatus';

export const Wrapper = styled.div<{ isDisabled: boolean }>(
    ({ theme }) => ({
        alignItems: 'center',
        border: `1px solid ${theme.colors.black}`,
        borderRadius: 2,
        cursor: 'pointer',
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        rowGap: theme.space[3],
        minHeight: theme.space[8],
        padding: theme.space[3],
        transition: 'background-color 200ms ease, border 200ms ease, box-shadow 200ms ease',
        marginBottom: theme.space[3],

        '&:hover': {
            borderColor: theme.colors.brandBlue,
        },

        '> div:first-of-type': {
            flex: 'auto',
        },

        '> div:last-of-type': {
            flex: 'auto',
            minWidth: '45px',
        },
    }),

    ifProp('isDisabled', ({ theme }) => ({
        pointerEvents: 'none',
        borderColor: theme.colors.grey70,
        color: theme.colors.grey70,

        [`${StockStatus}`]: {
            backgroundColor: theme.colors.grey50,
        },

        p: {
            color: theme.colors.grey70,
        },

        '&:hover': {
            borderColor: theme.colors.grey70,
        },
    })),
);

export const RadioButton = styled(LayoutRadioButton)(({ theme }) => ({
    height: theme.space[3],
    width: theme.space[3],
    margin: `0 ${theme.space[2]} 0 0`,
    '&:disabled': {
        span: {
            color: theme.colors.grey70,
        },
    },
}));

export const SectionSelector = styled.div(({ theme }) => ({
    display: 'flex',
    width: '100%',
    gap: theme.space[2],
    alignItems: 'baseline',
    flexDirection: 'row-reverse',
}));
export const DeliveryStatus = styled.span(({ theme }) => ({
    display: 'grid',
    alignContent: 'start',
    paddingTop: theme.space[1],
}));

export const DeliveryText = styled.span(({ theme }) => ({
    fontSize: theme.fontSizes.mouse,
    lineHeight: theme.lineHeights.squirrel,
    overflowWrap: 'break-word',
    letterSpacing: '-0.429px',
}));

export const Loader = styled.div<{ isActive: boolean }>(
    ({ theme }) => ({
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        minHeight: theme.space[8],
        justifyContent: 'center',
        padding: theme.space[2],
        position: 'relative',

        svg: {
            fill: theme.colors.nightBlack,
        },
    }),

    ifProp('isActive', ({ theme }) => ({
        borderColor: theme.colors.brandBlue,
        backgroundColor: theme.colors.offWhite,
    })),
);

export const StyledNotification = styled(ProductNotification)(({ theme }) => ({
    gridColumn: '1 / 2',
    justifySelf: 'end',
    width: 'calc(100% - 26px)',
    paddingBlock: theme.space[1],
}));

export const StyledList = styled.ul(() => ({
    listStyleType: 'none',
    overflowY: 'scroll',
}));
