import { useFormContext, useController } from 'react-hook-form';

export default function useFormInput(name: string) {
    const formContext = useFormContext();

    if (!formContext) {
        throw new Error('useFormInput must be used within a FormProvider.');
    }

    const {
        control,
        formState: { errors, defaultValues },
        register,
        setValue,
        watch,
    } = useFormContext();

    const {
        field: { onBlur },
    } = useController({ name, control });

    const error = errors?.[name]?.message;
    const inputValue = watch(name);

    return { error, inputValue, onBlur, register, defaultValues, setValue };
}
