import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Link } from '~/shared/components/Link';
import { breakpoints } from '~/theme';

export const LineItem = styled.div(({ theme }) => ({
    display: 'grid',
    backgroundColor: 'transparent',
    transition: 'width .2s ' + theme.easings.smoothEase,
    gap: 0,
    rowGap: 2,
    width: '100%',
    gridTemplateColumns: `min-content 1fr clamp(5ch, 15%, 50px)`,
    gridTemplateAreas:
        '"image type remove" "image name remove" "image variant variant" "image itemNumber itemNumber" "amount amount amount" "error error error"',

    [`> p`]: {
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    },

    [breakpoints.sm]: {
        [`> p, > a`]: {
            WebkitLineClamp: 2,
        },
    },

    [breakpoints.sm]: {
        rowGap: theme.space[1],
    },

    [breakpoints.sm]: {
        rowGap: theme.space[1],
        gridTemplateColumns: `min-content 1fr clamp(35ch, 60%, 350px) clamp(5ch, 15%, 60px)`,
        gridTemplateAreas:
            '"image type type remove" "image name name remove" "image variant variant variant" "image itemNumber amount amount" "error error error error"',
    },
}));

export const ItemImageContainer = styled.div(({ theme }) => ({
    position: 'relative',
    gridArea: 'image',
    width: theme.space[12],
    height: theme.space[12],
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    transition: 'width .4s ' + theme.easings.smoothEase,
    marginRight: theme.space[4],

    [breakpoints.sm]: {
        alignSelf: 'center',
        width: theme.space[12],
    },
}));

export const ItemImageWrapper = styled.div(({ theme }) => ({
    position: 'relative',
    width: theme.space[12],
    height: theme.space[12],
    padding: theme.space[2],
    backgroundColor: theme.colors.white,
    overflow: 'hidden',

    img: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

export const ItemName = styled(Link)(({ theme }) => ({
    gridArea: 'name',
    fontSize: theme.fontSizes.mouse,
    lineHeight: theme.lineHeights.rat,
    alignSelf: 'center',

    [breakpoints.sm]: {
        fontSize: theme.fontSizes.rat,
        lineHeight: theme.lineHeights.squirrel,
    },
}));

export const ItemNumber = styled.p<{ isCheckout?: boolean }>(
    ({ theme }) => ({
        gridArea: 'itemNumber',
        color: theme.colors.grey70,
        fontSize: theme.fontSizes.beetle,
        lineHeight: theme.lineHeights.frog,
        alignSelf: 'end',

        [breakpoints.sm]: {
            marginTop: 0,
            display: 'flex',
            fontSize: theme.fontSizes.mouse,
            lineHeight: theme.lineHeights.chicken,
            alignSelf: 'end',
        },
    }),
    ifProp('isCheckout', ({ theme }) => ({ paddingTop: theme.space[4] })),
);

export const ItemPrice = styled.p(({ theme }) => ({
    gridArea: 'price',
    textAlign: 'end',
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.squirrel,
    fontWeight: theme.fontWeights.medium,
    alignSelf: 'center',
    whiteSpace: 'nowrap',
}));

export const ItemPricePerItem = styled.p(({ theme }) => ({
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.squirrel,
    color: theme.colors.grey90,
}));

export const ItemQuantityContainer = styled.div(({ theme }) => ({
    alignItems: 'baseline',
    display: 'grid',
    marginTop: theme.space[3],
    gridArea: 'amount',
    gridTemplateColumns: '120px 1fr 1fr',

    [breakpoints.sm]: {
        gridAutoFlow: 'column',
        gridTemplateColumns: '170px 1fr auto',
        gridColumnGap: 24,
        marginTop: theme.space[1],
        alignItems: 'center',
    },
}));

export const ItemAmountSelector = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.space[3],
    [breakpoints.sm]: {
        justifySelf: 'end',
    },
}));

export const ItemType = styled.p(({ theme }) => ({
    gridArea: 'type',
    fontSize: theme.fontSizes.beetle,
    lineHeight: theme.lineHeights.rat,
    alignSelf: 'center',

    [breakpoints.sm]: {
        lineHeight: theme.lineHeights.chicken,
    },
}));

export const ItemVariant = styled.p(({ theme }) => ({
    gridArea: 'variant',
    fontSize: theme.fontSizes.beetle,
    lineHeight: theme.lineHeights.rat,
    alignSelf: 'center',

    [breakpoints.sm]: {
        fontSize: theme.fontSizes.mouse,
        lineHeight: theme.lineHeights.chicken,
        marginTop: theme.space[1],
    },
}));

export const ItemTypeVariant = styled.span({
    textTransform: 'capitalize',
});

export const ItemWrapper = styled.li(({ theme }) => ({
    width: '100%',
    listStyle: 'none',
    paddingInline: theme.space[3],
    [breakpoints.md]: {
        paddingInline: theme.space[6],
    },
}));

export const PriceWrapper = styled.div({ display: 'flex', justifyContent: 'end' });
