import { LoadingSpinner } from '~/shared/components/LoadingSpinner';
import { Loader } from '../styled';

export default function Loading(props: { isActive: boolean }) {
    return (
        <Loader {...props}>
            <LoadingSpinner />
        </Loader>
    );
}
