import { create } from 'zustand';

type ModalState = {
    customerCommentModalId: number;
    deliveryAddressModalId: number;
    deliveryAddressCancelCallback?: () => void;
    deliveryDateModalId: number;
    freightPriceModalId?: string;
    freightPriceSuccessCallback?: (args?: unknown) => void;
    setDeliveryAddressModalId: (id: number) => void;
    setDeliveryAddressCancelCallback: (callback?: (args?: unknown) => void) => void;
    setDeliveryDateModalId: (id: number) => void;
    setCustomerCommentModalId: (id: number) => void;
    setFreightPriceModalId: (id?: string) => void;
    setFreightPriceSuccessCallback: (callback?: (args?: unknown) => void) => void;
};

const useModalState = create<ModalState>((set) => ({
    customerCommentModalId: 0,
    deliveryAddressModalId: 0,
    deliveryDateModalId: 0,
    quickAddToBasketModalId: '',
    setCustomerCommentModalId: (id: number) => set({ customerCommentModalId: id }),
    setDeliveryAddressModalId: (id: number) => set({ deliveryAddressModalId: id }),
    setDeliveryAddressCancelCallback: (callback?: (args?: unknown) => void) =>
        set({ deliveryAddressCancelCallback: callback }),
    setDeliveryDateModalId: (id: number) => set({ deliveryDateModalId: id }),
    setFreightPriceModalId: (id?: string) => set({ freightPriceModalId: id }),
    setFreightPriceSuccessCallback: (callback?: (args?: unknown) => void) =>
        set({ freightPriceSuccessCallback: callback }),
}));

export default useModalState;
