import styled from '@emotion/styled';
import { ProductInfo } from '~/features/ProductDetail';
import { Brand, Header, Sku } from '~/features/ProductDetail/components/ProductInfo/styled';
import { Button } from '~/shared/components/Button';
import { Wrapper } from '~/shared/components/SelectAmount/styled';
import AddToBasket from '~/templates/pages/components/P50ProductDetailPage/components/AddToBasket';
import { breakpoints } from '~/theme';
import Link from 'next/link';

export const GridArea = styled.div(
    ({
        area,
    }: {
        area: 'header' | 'variants' | 'price' | 'freight' | 'buy' | 'info' | 'stock';
    }) => ({
        gridArea: area,
    }),
);

export const StyledInformation = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.space[4],
    zIndex: 1,

    [breakpoints.md]: {
        gridColumn: 'span 5',
    },
}));

export const StyledProductInfo = styled(ProductInfo)(({ theme }) => ({
    width: '80%',
    [`${Header}`]: {
        fontSize: theme.fontSizes.guinea,
        color: theme.colors.nightBlack,
        letterSpacing: -0.2,
        marginBottom: theme.space[1],
        lineHeight: theme.lineHeights.squirrel,
    },
    [`${Brand}`]: {
        fontSize: theme.fontSizes.beetle,
        color: theme.colors.nightBlack,
        lineHeight: theme.lineHeights.guinea,
        marginBottom: theme.space[1],
    },
    [`${Sku}`]: {
        color: theme.colors.grey70,
        fontSize: theme.fontSizes.beetle,
    },
}));

export const ImageWrapper = styled.div(({ theme }) => ({
    position: 'relative',
    width: theme.space[11],
    height: theme.space[11],
}));

export const ImageBackground = styled.div(({ theme }) => ({
    padding: theme.space[2],
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const ImageOverlay = styled.div({
    position: 'absolute',
    inset: 0,
    background: '#f5f5f3',
    mixBlendMode: 'multiply',
});

export const ProductData = styled.div(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: `auto ${theme.space[5]} auto ${theme.space[5]} auto ${theme.space[3]} auto ${theme.space[4]} auto auto auto`,
    gridTemplateAreas: "'header' '.' 'variants' '.' 'price' '.' 'freight' '.' 'buy' 'stock' 'info'",

    [breakpoints.md]: {
        maxWidth: 460,
        gridTemplateRows: `auto ${theme.space[6]} auto ${theme.space[6]} auto ${theme.space[3]} auto ${theme.space[4]} auto auto auto`,
        position: 'sticky',
        top: theme.space[11],
    },
}));

export const BottomProductDataWrapper = styled.div(() => ({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
}));

export const CampaignNotificationWrapper = styled.div(({ theme }) => ({
    marginBottom: theme.space[6],
}));

export const DemoNotificationWrapper = styled.div(({ theme }) => ({
    margin: `0 0 ${theme.space[4]} 0`,
}));

export const Container = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100%',
});

export const StyledAddToBasket = styled(AddToBasket)({
    [`${Wrapper}`]: {
        '--icon-width': '38px',
        '--input-width': '60px',

        [breakpoints.xxs]: {
            '--icon-width': '48px',
            '--input-width': '77px',
        },
    },
});

export const BottomButton = styled(Button)(({ theme }) => ({
    marginBottom: theme.space[5],
}));

export const SkeletonWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.space[1],
}));

export const StyledProductLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: theme.space[4],
}));

export const VariantSelectorButton = styled.button<{ isOpen?: boolean }>(({ isOpen, theme }) => ({
    backgroundColor: 'transparent',
    borderRadius: isOpen ? '2px 2px 0 0' : '2px 2px',
    border: `1px solid ${theme.colors.grey50}`,
    lineHeight: '15px',
    padding: '15px 10px',
    textAlign: 'left',
    width: '100%',
    color: theme.colors.black,

    'span:first-of-type': {
        textTransform: 'capitalize',
    },

    svg: {
        height: theme.space[4],
        overflow: 'visible',
        width: theme.space[4],
    },
}));

export const VariantSelectorItem = styled.li<{ isHighlighted?: boolean }>(
    ({ isHighlighted, theme }) => ({
        backgroundColor: isHighlighted ? theme.colors.brandBlue : 'inherit',
        borderBottom: `1px solid ${theme.colors.grey30}`,
        color: isHighlighted ? theme.colors.white : 'inherit',
        cursor: 'pointer',
        padding: '15px 10px',
        width: '100%',

        '&:hover': {
            backgroundColor: isHighlighted ? theme.colors.brandBlue : theme.colors.grey10,
        },
    }),
);

export const VariantSelectorWrapper = styled.div(({ theme }) => ({
    backgroundColor: 'transparent',
    fontSize: '12px',
    lineHeight: '15px',
    position: 'relative',

    '> ul': {
        backgroundColor: theme.colors.white,
        boxShadow: '0 5px 15px 0 rgb(0 0 0 / 20%)',
        left: 0,
        listStyle: 'none',
        maxHeight: '320px',
        overflowY: 'auto',
        position: 'absolute',
        top: '100%',
        width: '100%',
        zIndex: theme.zIndices[3],
    },
}));
