import { useEffect, useState } from 'react';
import { useProduct, useProductImages } from '~/services';
import { ImageOverlay, ProductImage } from '~/shared/components/Image';
import { VideolyScript } from '~/shared/scripts';
import { FeaturedImage, FeaturedImageWrapper, GalleryWrapper, GridImage } from './styled';

type Props = {
    itemNo: string;
    onClick: (index: number) => void;
};

export const ProductMedia = ({ onClick, itemNo }: Props) => {
    const { data: images } = useProductImages();
    const { data: product } = useProduct(itemNo, ({ product }) => product?.item);
    const [featuredMedia, setFeaturedMedia] = useState(images ? images[0] : undefined);
    const productItemTitle = `${product?.name ? `${product?.name} ` : ''}${product?.name2 ?? ''}`;

    useEffect(() => {
        setFeaturedMedia(images ? images[0] : undefined);
    }, [images]);

    return (
        <>
            <GalleryWrapper>
                {featuredMedia ? (
                    <FeaturedImageWrapper>
                        <FeaturedImage>
                            {featuredMedia.link ? (
                                <ProductImage
                                    alt={featuredMedia.displayName ?? ''}
                                    priority
                                    src={featuredMedia.link}
                                    fill
                                    sizes="(max-width: 768px) 100vw,"
                                    style={{ objectFit: 'contain' }}
                                />
                            ) : null}
                        </FeaturedImage>

                        <ImageOverlay />
                    </FeaturedImageWrapper>
                ) : null}

                {images?.map((media, index) => {
                    if (!media.link) {
                        return null;
                    }

                    return (
                        <GridImage
                            oneChildOnly={images.length === 1}
                            key={`${media.link}-${index}`}
                            isActiveMedia={media.link === featuredMedia?.link}
                            onClick={() => {
                                setFeaturedMedia(media);
                                onClick(index);
                            }}
                        >
                            <ProductImage
                                alt={media.displayName ?? ''}
                                priority={featuredMedia?.displayName === media.displayName}
                                fill
                                sizes="(max-width: 768px) 60vw"
                                src={media.link}
                                style={{
                                    objectFit: 'contain',
                                }}
                            />
                            <ImageOverlay />
                        </GridImage>
                    );
                })}
            </GalleryWrapper>

            <VideolyScript
                productItemNumber={product?.itemNumber}
                productItemTitle={productItemTitle.trimEnd()}
                productItemEan={product?.ean}
            />
        </>
    );
};
