import { useSelect } from 'downshift';
import { useEffect, useState } from 'react';
import ChevronDown from '$icons/iconography/chevron/down.svg';
import Minus from '$icons/iconography/minus.svg';
import { Flex } from '~/shared/components/Basics';
import { VariantSelectorButton, VariantSelectorItem, VariantSelectorWrapper } from './styled';

interface Variant {
    title?: string;
    value?: string;
    url?: string;
}

interface VariantSelectorProps {
    label?: string;
    selectedVariantValue?: string;
    variantList: Variant[];
    setItemNumber: any;
}

export const VariantSelector = ({
    label,
    selectedVariantValue,
    variantList,
    setItemNumber,
}: VariantSelectorProps) => {
    const [selectedItem, setSelectedItem] = useState<Variant>(
        variantList.find((variant) => variant.value === selectedVariantValue) ?? variantList[0],
    );

    const { isOpen, getToggleButtonProps, getLabelProps, getMenuProps, getItemProps } = useSelect({
        id: 'variant-selector',
        items: variantList,
        onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
            if (!newSelectedItem) {
                return;
            }

            // Shallowly change url to reflect variant change
            const variantUrlSegment = variantList?.find(
                (variant) => variant.value === newSelectedItem.value,
            )?.url;

            if (!variantUrlSegment) {
                return;
            }

            setItemNumber(newSelectedItem.value);
        },
    });
    useEffect(() => {
        setSelectedItem(
            variantList.find((variant) => variant.value === selectedVariantValue) ?? variantList[0],
        );
    }, [selectedVariantValue, variantList]);

    return (
        <VariantSelectorWrapper>
            <VariantSelectorButton isOpen type="button" {...getToggleButtonProps()}>
                <Flex flow="row" align="center" justify="between" {...getLabelProps()}>
                    {label ? (
                        <p>
                            <span>{label}: </span>
                            <span>{selectedItem?.title}</span>
                        </p>
                    ) : (
                        <p>{selectedItem?.title}</p>
                    )}

                    {isOpen ? <Minus /> : <ChevronDown />}
                </Flex>
            </VariantSelectorButton>

            <ul {...getMenuProps()}>
                {isOpen &&
                    variantList.map((variant, index) => (
                        <VariantSelectorItem
                            key={variant.value ?? variant.title}
                            isHighlighted={selectedItem?.value === variant.value}
                            {...getItemProps({ item: variant, index })}
                        >
                            <p>{variant.title ?? variant.value}</p>
                        </VariantSelectorItem>
                    ))}
            </ul>
        </VariantSelectorWrapper>
    );
};
