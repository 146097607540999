import styled from '@emotion/styled';
import { Button } from '~/shared/components/Button';
import { Text } from '~/shared/components/Text';
import { breakpoints } from '~/theme';

export const Wrapper = styled.div(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    paddingTop: theme.space[5],
    gridRowGap: theme.space[4],
}));

export const PageTitle = styled(Text)(({ theme }) => ({
    fontSize: theme.fontSizes.cat,
    paddingInline: theme.space[3],

    [breakpoints.md]: {
        fontSize: theme.fontSizes.wolf,
        paddingInline: theme.space[6],
    },
}));

export const ButtonWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.space[2],
    paddingInline: theme.space[3],

    justifyContent: 'space-between',
    ['> button']: {
        flex: 1,
    },
    ['> a']: {
        flex: 1,
    },

    [breakpoints.md]: {
        paddingInline: theme.space[6],
    },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    border: `1px solid ${theme.colors.black}`,
    color: theme.colors.black,
    flex: 1,
}));

export const ProductCardWrapper = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.grey10,
    minHeight: '450px',
}));
