import styled from '@emotion/styled';
import { Link as CustomLink } from '~/shared/components/Link';
import Link from 'next/link';
import { Text as GenericText } from '~/shared/components/Text';
import { breakpoints } from '~/theme';

export const Wrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
});

export const Header = styled(GenericText)(({ theme }) => ({
    fontWeight: theme.fontWeights.normal,
    textAlign: 'left',
    letterSpacing: '-0.5px',
    marginBottom: 25,
}));

export const Brand = styled(GenericText)(({ theme }) => ({
    color: theme.colors.grey70,
    textAlign: 'left',
    letterSpacing: '-0.1px',
    marginBottom: theme.space[2],
    order: -1,

    [breakpoints.xs]: {
        fontSize: theme.fontSizes.mouse,
    },
}));

export const StyledBrandLink = styled(CustomLink)(({ theme }) => ({
    color: theme.colors.grey70,
    textAlign: 'left',
    letterSpacing: '-0.1px',
    marginBottom: theme.space[2],
    order: -1,
    lineHeight: theme.lineHeights.fox,

    [breakpoints.xs]: {
        fontSize: theme.fontSizes.mouse,
    },

    [breakpoints.md]: {
        fontSize: theme.fontSizes.guinea,
        lineHeight: theme.lineHeights.dog,
    },
}));

export const SkuContainer = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: theme.fontSizes.mouse,
    lineHeight: '150%',
}));

export const Sku = styled(GenericText)(({ theme }) => ({
    fontSize: theme.fontSizes.mouse,
    lineHeight: '150%',
    marginBottom: theme.space[1],
    letterSpacing: '-0.1px',
}));

export const ComplementaryProductsLink = styled(CustomLink)(({ theme }) => ({
    color: theme.colors.brandBlue,
    fontSize: theme.fontSizes.chicken,
    textAlign: 'right',
}));

export const Text = styled(GenericText)({
    letterSpacing: '-0.1px',
    whiteSpace: 'pre-wrap',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
});

export const ReadMore = styled(CustomLink)(({ theme }) => ({
    appearance: 'none',
    backgroundColor: 'transparent',
    border: 0,
    cursor: 'pointer',
    margin: 0,
    padding: 0,
    fontSize: theme.fontSizes.rat,
    lineHeight: theme.lineHeights.dog,
    display: 'inline',
    alignSelf: 'flex-start',
}));

export const SplashesWrapper = styled.ul(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.space[1],
    marginBottom: theme.space[3],
    order: -2,

    [breakpoints.md]: {
        marginBottom: theme.space[2],
    },
}));

export const Splash = styled.li(({ theme }) => ({
    whiteSpace: 'nowrap',
    fontSize: theme.fontSizes.beetle,
    lineHeight: theme.lineHeights.mouse,
    padding: '4px 12px',
    letterSpacing: -0.29,
}));

export const StyledLink = styled(Link)(() => ({
    color: 'inherit',
    textDecoration: 'none',
    ':hover': {
        textDecoration: 'underline',
    },
}));
