import { useEffect } from 'react';
import { useFreight, useStockDetails, useTranslations } from '~/services';
import { ProductOnlineStatus } from '~/shared/components/ProductOnlineStatus';
import { useCurrency, useIsMounted } from '~/shared/hooks';
import { FreightTypes, useFreightType, usePostalCode } from '~/shared/utils/storage';
import {
    DeliveryPrice,
    DeliveryStatus,
    DeliveryText,
    FreightTrigger,
    StatusWrapper,
    StyledInfoText,
    Wrapper,
} from '../styled';
import { ProductStatusProps } from '../types';
import Loading from './Loading';

export default function OnlineStock({
    itemNo,
    productStatus,
    setFreightHandler,
    productIsOutOfStock,
}: ProductStatusProps) {
    const { translate } = useTranslations();
    const formatCurrency = useCurrency();
    const isMounted = useIsMounted();
    const [postalCode] = usePostalCode();
    const [freightType, setFreightType] = useFreightType();
    const { data: stockDetails, isLoading, isError } = useStockDetails({ itemNo, postalCode });
    const { data: freight } = useFreight({
        postalCode,
        itemNo,
    });
    const freightPrice = freight?.totalPrice?.priceInclVat;

    // Preselect if only Online freight has stock
    useEffect(() => {
        const shouldPreSelectOnlineFreight =
            !isLoading && stockDetails?.online && !stockDetails?.pickup;

        if (shouldPreSelectOnlineFreight) {
            setFreightType(FreightTypes.Online);
        }
        // Only run when stock details is done loading
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    const freightTypeIsOnline = freightType === FreightTypes.Online;

    if (isLoading && !isError) {
        return <Loading isActive={freightTypeIsOnline && isMounted()} />;
    }

    if (isError || !stockDetails?.online || productStatus === 'Obsolete' || productIsOutOfStock) {
        return (
            <Wrapper noStockDetails isActive={false}>
                <StyledInfoText as="p">{translate('product.notAvailableOnline')}</StyledInfoText>
            </Wrapper>
        );
    }

    const { title, priceText, status, requiresPostalCode } = stockDetails.online;

    return (
        <>
            <StatusWrapper>
                {status ? (
                    <DeliveryStatus>
                        <ProductOnlineStatus status={status} />
                    </DeliveryStatus>
                ) : null}
                <DeliveryText>{translate(title?.name, title?.values)}</DeliveryText>
                <DeliveryPrice>
                    {requiresPostalCode ? (
                        <>
                            <FreightTrigger
                                as="button"
                                onClick={() => {
                                    if (setFreightHandler) {
                                        setFreightHandler(itemNo || '');
                                    }
                                }}
                                hasPostalCode={!!postalCode}
                            >
                                {postalCode
                                    ? `${translate(
                                          'freightCalculator.postalCodeShort',
                                      )} ${postalCode}:`
                                    : translate('freightCalculator.calculateFreight')}
                            </FreightTrigger>

                            {typeof freightPrice !== 'undefined'
                                ? freightPrice === 0
                                    ? translate('generic.price.free')
                                    : formatCurrency(freightPrice)
                                : null}
                        </>
                    ) : priceText?.values?.[0]?.value === 0 ? (
                        translate('generic.price.free')
                    ) : (
                        `${translate('generic.from')} ${translate(
                            priceText?.name,
                            priceText?.values,
                        )} `
                    )}
                </DeliveryPrice>
            </StatusWrapper>
        </>
    );
}
