import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { ifProp } from 'styled-tools';
import { Gutter } from '~/shared/components/Gutter';
import { StyledResetButton } from '~/shared/styles/Button';
import { breakpoints } from '~/theme';

export const StyledGrabWrapper = styled(motion.div)(
    ifProp('drag', {
        cursor: 'grab',
        ':active': {
            cursor: 'grabbing',
        },
    }),
);

export const StyledItemsWrapper = styled.div<{ isProducts: boolean }>(
    ({ isProducts, theme: { space } }) => ({
        position: 'relative',
        display: 'flex',
        gap: isProducts ? space[2] : space[5],
        paddingBottom: space[8],
        [`@container Carousel (width < 725px)`]: {
            paddingBottom: space[3],
            gap: space[2],
        },
    }),
);

export const StyledPagination = styled.div(({ theme }) => ({
    textAlign: 'right',
    display: 'flex',
    gap: theme.space[2],
}));

export const StyledPaginationButton = styled(StyledResetButton)(({ theme }) => ({
    fontSize: theme.fontSizes.squirrel,
    padding: theme.space[1],

    ':is(:hover, :focus)': {
        backgroundColor: theme.colors.offWhite,
    },

    ':disabled': {
        opacity: 0.3,
    },

    svg: {
        width: 24,
        height: 24,
    },
}));

export const StyledGutter = styled(Gutter)(({ theme }) => ({
    [`@container Carousel (width < 725px)`]: {
        paddingBottom: 0,
        paddingInline: theme.space[3],

        [breakpoints.md]: {
            paddingInline: theme.space[6],
        },
    },
}));

export const StyledHeaderGutter = styled(Gutter)(({ theme }) => ({
    [`@container Carousel (width < 725px)`]: {
        gap: 0,
        ['* > h2']: {
            fontSize: theme.fontSizes.cat,
        },

        paddingInline: theme.space[3],
        [breakpoints.sm]: {
            paddingInline: theme.space[6],
        },
    },
}));
